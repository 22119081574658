import React from 'react'
import { Header, LayoutWrapper, RightContainer, Text, Wrapper } from './styled-components'
import Drawer from 'components/common/Drawer'
import LinkQuestionsToModuleDrawer from './LinkQuestionsToModuleDrawer/LinkQuestionsToModuleDrawer'
import FilterDrawer from './FilterDrawer'
import Grid from 'components/common/GridV2'
import Pagination from 'components/common/Pagination'
import Breadcrumb from 'components/common/BreadCrumbV2'
import {
  GridLayoutActions,
  MultipleActionTooltip,
  StudentsActionsContainer,
} from 'components/common/GridV2/styled-components'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { ToolbarItem } from 'components/common/Toolbar'
import IconButton from '@mui/material/IconButton'
import { FilterIcon } from 'suada-components'
import TextInput from 'components/common/TextInput/TextInput'
import { Search } from 'suada-components'
import { NewFilter } from 'suada-components'
import _ from 'lodash'
import useQuestionsLayout from 'hooks/questions/useQuestionsLayout'
import { SplashScreen } from 'components/common'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IUseQuestionsLayoutReturn } from 'hooks/questions/useQuestionsLayout.interface'
import { RemoveFilter } from 'suada-components'
import Tooltip from '@mui/material/Tooltip'
import MyLoader from 'loading/loading'
import { IQuestionLayoutProps } from './questionsLayout.interface'

const QuestionsLayout = ({
  topics,
  currentModule,
  refetchModule,
  manualCompanyId,
  canModify,
}: IQuestionLayoutProps): React.JSX.Element => {
  canModify = canModify === undefined ? true : canModify
  const {
    breadCrumbData,
    selectedItem,
    createButton,
    currentPage,
    t,
    setFilterDrawerOpened,
    isFiltered,
    handleSearchSubmit,
    searchValue,
    handleSearchChange,
    loading,
    actions,
    config,
    cards,
    selectAllItem,
    selectItem,
    redirectToQuestion,
    questions,
    handlePaginationClick,
    LinkQuestionDrawerOpened,
    handleCloseDrawer,
    setLinkQuestionDrawerOpened,
    refetch,
    filterDrawerOpened,
    handleFilterClick,
    setIsFiltered,
    filterData,
    setFilterData,
    setCurrentPage,
    companyId,
    isSearchType,
    resetFilter,
  } = useQuestionsLayout({
    topics,
    currentModule,
    refetchModule,
    manualCompanyId,
    canModify,
  }) as IUseQuestionsLayoutReturn

  if (
    !loading &&
    companyId &&
    searchValue === '' &&
    !isSearchType &&
    !questions?.data.length &&
    !isFiltered
  ) {
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen
          title={t('questions_layout.no_questions')}
          subTitle={t('questions_layout.create_questions')}
          createButton={createButton}
        />
      </>
    )
  }

  return (
    <>
      <Breadcrumb data={breadCrumbData} />

      <LayoutWrapper>
        <Header>
          <Wrapper>
            <Wrapper>
              <div>{createButton()}</div>
              <GridLayoutActions>
                <GridMultipleActions
                  selectedItems={
                    (selectedItem[currentPage] && selectedItem[currentPage].length) || 0
                  }
                  actions={[]}
                />
              </GridLayoutActions>
            </Wrapper>
            <RightContainer>
              <ToolbarItem $noGap>
                <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                  <Tooltip title={t('actions.filter')} arrow>
                    {isFiltered ? <NewFilter /> : <FilterIcon />}
                  </Tooltip>
                </IconButton>
                {isFiltered && (
                  <IconButton onClick={resetFilter}>
                    <Tooltip title={t('actions.remove_filter')} arrow>
                      <RemoveFilter />
                    </Tooltip>
                  </IconButton>
                )}
                <MultipleActionTooltip>{t('actions.filters')}</MultipleActionTooltip>
              </ToolbarItem>
              <form onSubmit={handleSearchSubmit}>
                <TextInput
                  label={t('general.search_placeholder')}
                  type='text'
                  size='small'
                  value={searchValue}
                  onChange={handleSearchChange}
                  icon={
                    <IconButton type='submit'>
                      <Search />
                    </IconButton>
                  }
                  $iconInStart
                />
              </form>
              {currentModule && <Text>{t('questions_layout.questions_recommendation')}</Text>}
              {loading && !cards && <div>{t('general.loading')}...</div>}
            </RightContainer>
          </Wrapper>
        </Header>
        <>
          {loading ? (
            <div>
              <MyLoader />
            </div>
          ) : cards.length === 0 ? (
            <SplashScreen
              title={t('general.no_results_found')}
              subTitle={t('general.try_adjusting_your_search_or_filter')}
              textMarginTop='40px'
              isSearchOrFilter={true}
            />
          ) : (
            <>
              <Grid
                actionConfig={actions}
                actionTilesLength={4}
                config={config}
                data={cards}
                selectedItems={selectedItem[currentPage] || []}
                selectAllItem={selectAllItem}
                selectItem={selectItem}
                selected={_.size(selectedItem[currentPage] || [])}
                redirectToDetailsHandler={redirectToQuestion}
                width={40}
                indexOfSpecificWidth={0}
                ActionsContainer={StudentsActionsContainer}
              />

              <Pagination
                currentPage={questions ? questions.currentPage : 1}
                totalPages={questions ? questions.totalPages : 0}
                handleChange={handlePaginationClick}
              />
            </>
          )}
        </>
      </LayoutWrapper>
      <Drawer
        opened={LinkQuestionDrawerOpened}
        toggleDrawer={(): void => undefined}
        totalWidth='800px'
      >
        <LinkQuestionsToModuleDrawer
          title={t('questions_layout.link_questions_to_module')}
          handleCloseDrawer={handleCloseDrawer}
          closeDrawer={(): void => setLinkQuestionDrawerOpened(false)}
          currentModule={currentModule as IModule}
          refetch={(): void => {
            refetch()
            if (refetchModule) {
              refetchModule()
            }
          }}
          currentModuleQuestionsLength={cards.length}
          manualCompanyId={manualCompanyId}
        />
      </Drawer>
      <Drawer opened={filterDrawerOpened} toggleDrawer={(): void => undefined} totalWidth='600px'>
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          handleFilterClick={handleFilterClick}
          setIsFiltered={setIsFiltered}
          filterData={filterData}
          quizModel={currentModule ? 'module' : 'general'}
          setFilterData={setFilterData}
          refetch={refetch}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
    </>
  )
}

export default QuestionsLayout
