import React from 'react'
import { Button } from 'components/common/Button'
import IconButton from '@mui/material/IconButton'
import { DrawerContent } from 'components/DrawerLayout/styled-components'
import { FormButtons, FormField, FormFooter } from 'components/common/Form'
import { IGroup } from 'interfaces/groups'
import {
  TasksHeader,
  DrawerContentContainer,
  FilterHeader,
  FilterTitle,
  TasksContainer,
  PaginationContainer,
  StyledTableContainer,
  SearchFields,
} from '../styled-components'
import TextInput from 'components/common/TextInput/TextInput'
import { Search } from 'suada-components'
import useAddTaskToGroup from 'hooks/tasks/useAddTaskToGroup'
import Grid from 'components/common/GridV2/Grid'
import _ from 'lodash'
import MyLoader from 'loading/loading'
import { SplashScreen } from 'components/common/SplashScreen'
import { LoaderContainer } from 'pages/tests/testDetails/studentsLayout/styled-components'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import Pagination from 'components/common/PaginationV2'
import Select from 'react-select'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'

const AddTaskToGroup = ({
  groupData,
  closeDrawer,
}: {
  groupData: IGroup
  closeDrawer: () => void
  taskId?: string
}): React.JSX.Element => {
  const {
    t,
    showNoResultsMessage,
    searchValue,
    handleSearchChange,
    selectAllItem,
    tasks,
    selectedItem,
    selectItem,
    gridConfig,
    currentPage,
    onFormSubmit,
    loading,
    hasNoTasks,
    totalPages,
    perPage,
    handleChangeRowsPerPage,
    handlePaginationClick,
    handleTagSearchChange,
    tagList,
    setFieldValue,
    values,
  } = useAddTaskToGroup({ groupData, closeDrawer })

  return (
    <>
      <CustomDrawerHeader title={t('tasks_layout.add_task')} onClose={closeDrawer} />
      <DrawerContent headerHeight={68}>
        <FilterHeader>
          <FilterTitle>{t('actions.filters')}</FilterTitle>
        </FilterHeader>
        <SearchFields>
          <FormField width='48%'>
            <TextInput
              label={t('general.search_by_name')}
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type='submit'>
                  <Search />
                </IconButton>
              }
              $iconInStart
            />
          </FormField>
          <FormField width='48%'>
            <Select
              isMulti
              onChange={(value): void => {
                const tags = value ? [...value] : []
                const tagLabels = value ? value.map(v => v.label).join(', ') : ''

                setFieldValue('tags', tags)
                handleTagSearchChange({
                  target: { value: tagLabels },
                } as React.ChangeEvent<HTMLInputElement>)
              }}
              options={tagList}
              value={values.tags}
              placeholder={t('form_fields.select_tags')}
            />
          </FormField>
        </SearchFields>
        <DrawerContentContainer>
          <TasksHeader>
            <FilterTitle>{t('general.tasks')}</FilterTitle>
          </TasksHeader>
          <TasksContainer>
            {loading ? (
              <LoaderContainer>
                <MyLoader />
              </LoaderContainer>
            ) : (
              tasks && (
                <>
                  {hasNoTasks ? (
                    <SplashScreen
                      title={t('general.no_results_found')}
                      subTitle={showNoResultsMessage ? t('general.try_adjusting_your_search') : ''}
                      textMarginTop='40px'
                      isSearchOrFilter={true}
                    />
                  ) : (
                    <StyledTableContainer>
                      <Grid
                        data={tasks}
                        config={gridConfig || []}
                        selectItem={selectItem}
                        selectAllItem={selectAllItem}
                        selected={_.size(selectedItem[currentPage])}
                        selectedItems={selectedItem[currentPage] || []}
                      />
                      <PaginationContainer>
                        <PerPageDropdown
                          value={perPage}
                          onChange={(value): void =>
                            handleChangeRowsPerPage({
                              target: {
                                value: value.toString(),
                              } as HTMLInputElement,
                            } as React.ChangeEvent<HTMLInputElement>)
                          }
                          options={[10, 20, 50]}
                        />
                        <Pagination
                          currentPage={currentPage ?? 1}
                          totalPages={totalPages ?? 1}
                          handleChange={handlePaginationClick}
                        />
                      </PaginationContainer>
                    </StyledTableContainer>
                  )}
                </>
              )
            )}
          </TasksContainer>

          <FormFooter>
            <div
              style={{
                backgroundColor: 'white',
                position: 'relative',
                zIndex: 2,
              }}
            >
              <FormButtons>
                <Button
                  text={t('actions.save')}
                  type='small'
                  onClick={onFormSubmit}
                  background='#06C68F'
                />
                <Button
                  text={t('actions.cancel')}
                  type='small'
                  onClick={closeDrawer}
                  background='#E0E1E2'
                  textColor='#414141'
                />
              </FormButtons>
            </div>
          </FormFooter>
        </DrawerContentContainer>
      </DrawerContent>
    </>
  )
}

export default AddTaskToGroup
