import React from 'react'

import { Action } from 'pages/group/styled-components'

import Tooltip from '@mui/material/Tooltip'
import { TrashCan } from 'suada-components'
import { TFunction } from 'interfaces/TFunction'
import { IAction } from 'components/common/GridV2'

export const actionConfig = (
  handleDeleteClick: (questions: string[]) => void,
  t: TFunction<'translation', undefined>,
): IAction[] => {
  const action: IAction[] = [
    {
      render: item => (
        <Action
          onClick={(): void => handleDeleteClick([item.id])}
          $hoverColor='rgb(231, 40, 21)'
          $hoverBackground='rgb(253, 237, 236)'
        >
          <Tooltip title={t('actions.delete')} arrow>
            <TrashCan />
          </Tooltip>
        </Action>
      ),
    },
  ]
  return action
}
