import React, { ReactElement } from 'react'
import { StatisticFlexBox, StatisticTitle, StatisticFlex } from './styled-components'

import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay'
import { Group } from 'suada-components'
import { Education } from 'suada-components'
import { UserSpeaker } from 'suada-components'
import Tile from 'components/common/TileV2'
import { IStatisticsProps } from './coursesAnalytics.interface'
import { useTranslation } from 'react-i18next'
import useCustomCardStyles from 'hooks/tileCards/useCustomCardStyle'

const Statistic = ({ additionalQuantities, loading }: IStatisticsProps): ReactElement => {
  const { t } = useTranslation()

  const customCardStyles = useCustomCardStyles({
    smallWidth: '250px',
    mediumWidth: '280px',
    largeWidth: '318px',
    smallBreakpoint: 1530,
    mediumBreakpoint: 1670,
  })

  return (
    <StatisticTitle>
      <StatisticFlex>
        <StatisticFlexBox>
          {additionalQuantities && (
            <>
              <Tile
                customCardStyles={customCardStyles}
                value={loading ? t('general.loading') : additionalQuantities.coursesQuantity}
                text={t('general.total_courses')}
                gap={50}
                icon={<PlaylistPlayIcon />}
                iconColor='#08C694'
                $backgroundColor='#E1F5E3'
              />

              <Tile
                customCardStyles={customCardStyles}
                value={loading ? t('general.loading') : additionalQuantities.groupsQuantity}
                text={t('general.total_unique_groups')}
                gap={50}
                icon={<Group fill='#0074D9' />}
                iconColor='#0074D9'
                $backgroundColor='#E1EBFA'
              />

              <Tile
                customCardStyles={customCardStyles}
                value={loading ? t('general.loading') : additionalQuantities.studentsQuantity}
                text={t('general.total_unique_students')}
                gap={50}
                icon={<Education />}
                iconColor='#8438C6'
                $backgroundColor='#F1DFF7'
              />

              <Tile
                customCardStyles={customCardStyles}
                value={
                  loading ? t('general.loading') : additionalQuantities.skillTestVideosQuantity
                }
                text={t('general.total_skill_test_videos')}
                gap={50}
                icon={<UserSpeaker />}
                iconColor='#20A8C6'
                $backgroundColor='#DAEDF2'
              />
            </>
          )}
        </StatisticFlexBox>
      </StatisticFlex>
    </StatisticTitle>
  )
}

export default Statistic
