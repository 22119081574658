import { IconButton, Tooltip } from '@mui/material'
import { Drawer, Modal, SplashScreen, TextInput } from 'components/common'
import { GridLayoutActions, GridToolbar } from 'components/common/Grid/styled-components'
import { ToolbarItem } from 'components/common/Toolbar'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import useCoachesLayout from 'hooks/coaches/useCoachesLayout'
import { FilterCoaches } from 'pages/coaches'
import { FlexComponent, LayoutWrapper, StyledPagination } from 'pages/coaches/styled-components'
import React, { ReactElement, useMemo } from 'react'
import { FilterIcon } from 'suada-components'
import { NewFilter } from 'suada-components'
import Grid from 'components/common/GridV2'
import _ from 'lodash'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import PaginationV2 from 'components/common/PaginationV2'
import { IGroup } from 'interfaces/groups'
import { IUserCompany } from 'interfaces/users'
import MyLoader from 'loading/loading'

const GroupTutors = ({ group }: { group: IGroup }): ReactElement => {
  const tutorIds = useMemo(() => {
    const ids =
      group?.courses?.map(course => course.coach?.coachId).filter(id => id !== undefined) || []

    return ids
  }, [group?.courses])

  const {
    t,
    filterDrawerOpened,
    setFilterDrawerOpened,
    filter,
    setFilterOptions,
    filterOptions,
    state,
    loading,
    users,
    selectedItem,
    currentPage,
    searchValue,
    handleSearchChange,
    isFiltered,
    groupTutorsConfig,
    selectAllItem,
    selectItem,
    handleChangeRowsPerPage,
    handlePaginationClick,
    pushNotificationModal,
    handleModalClose,
    perPage,
    messageData,
    setMessageData,
    handlePushNotificationSend,
    initialLoad,
    isSearchType,
  } = useCoachesLayout({
    tutorIds,
    suadaTutorsIncluded: true,
  })

  return (
    <>
      {!loading &&
      users &&
      !isSearchType &&
      !filterOptions &&
      users?.data?.length === 0 &&
      !searchValue ? (
        <SplashScreen
          title={t('user_details.no_tutors')}
          subTitle={t('user_details.create_tutors')}
          marginTop={20}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      ) : (
        <LayoutWrapper $loading={loading}>
          <Drawer opened={filterDrawerOpened} totalWidth='600px'>
            <FilterCoaches
              role='COACH'
              closeDrawer={(): void => setFilterDrawerOpened(false)}
              filter={filter}
              filterLoading={loading}
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              hasSearchByCompany
              manualCompanyId={(state?.currentUser?.companyId as IUserCompany)?.id}
              useCompanyId={
                !!(state?.currentUser?.companyId && state.currentUser.companyId !== null)
              }
            />
          </Drawer>
          {!initialLoad && (
            <GridToolbar>
              <GridLayoutActions></GridLayoutActions>
              <FlexComponent>
                <ToolbarItem>
                  <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                    <Tooltip title={t('actions.filter')} arrow>
                      {isFiltered ? <NewFilter /> : <FilterIcon />}
                    </Tooltip>
                  </IconButton>
                </ToolbarItem>
                <form onSubmit={(e): void => e.preventDefault()}>
                  <TextInput
                    label={t('general.search_placeholder')}
                    type='text'
                    size='small'
                    value={searchValue}
                    onChange={handleSearchChange}
                    icon={
                      <IconButton type='submit'>
                        <SearchRoundedIcon />
                      </IconButton>
                    }
                    $iconInStart
                    name='search'
                  />
                </form>
              </FlexComponent>
            </GridToolbar>
          )}
          <div>
            {loading ? (
              <MyLoader />
            ) : (
              users && (
                <>
                  {users.data.length === 0 ? (
                    <SplashScreen
                      title={t('general.no_results_found')}
                      subTitle={t('general.try_adjusting_your_search_or_filter')}
                      textMarginTop='40px'
                      isSearchOrFilter={true}
                    />
                  ) : (
                    <Grid
                      config={groupTutorsConfig}
                      actionTilesLength={3}
                      data={users.data}
                      selected={_.size(selectedItem[currentPage])}
                      selectAllItem={selectAllItem}
                      selectItem={selectItem}
                      selectedItems={selectedItem[currentPage] || []}
                    />
                  )}
                  {users.data.length > 0 && (
                    <StyledPagination>
                      <PerPageDropdown
                        value={perPage}
                        onChange={(value): void =>
                          handleChangeRowsPerPage({
                            target: {
                              value: value.toString(),
                            } as HTMLInputElement,
                          } as React.ChangeEvent<HTMLInputElement>)
                        }
                        options={[10, 20, 50]}
                      />
                      <PaginationV2
                        currentPage={currentPage}
                        totalPages={users?.totalPages || 0}
                        handleChange={handlePaginationClick}
                      />
                    </StyledPagination>
                  )}
                </>
              )
            )}
          </div>
          <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
            <SendPushNotification
              messageData={messageData}
              setMessageData={setMessageData}
              onClose={handleModalClose}
              onSend={handlePushNotificationSend}
            />
          </Modal>
        </LayoutWrapper>
      )}
    </>
  )
}

export default GroupTutors
