import React, { ReactElement } from 'react'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import {
  AI_assistantWrapper,
  ButtonsWrapper,
  CourseContentDetails,
  CourseContentDetailsContainer,
  CourseContentDetailsContainerFlex,
  CourseContentDetailsRight,
  CourseDetailsWrapper,
  StyledCourseDetailsBetweenParent,
  StyledCourseDetailsFlex,
  StyledCourseDetailsLeft,
  StyledCourseDetailsLeftParent,
  StyledCourseDetailsSkillsParent,
} from './styled-components'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import Breadcrumb from 'components/common/BreadCrumbV2'
import Player from 'components/common/VideoPlayer/Player'
import { AssessmentsTab, ModulesTab } from './Tabs'
import MyLoader from 'loading/loading'
import Modal from 'components/common/Modal'
import {
  ActionsWrapper,
  ModuleContentDetail,
  ModuleContentDetailsHeader,
  ModulesContentWrapper,
  styles,
} from 'pages/modules/ModuleDetail/ModuleDetailContent/styled-components'
import { formatDuration } from 'helpers/formatDuration'
import { TagsGrid } from 'pages/tests/testDetails/styled-components'
import Tags from 'components/common/Tags/Tags'
import { CenteredText } from 'pages/tests/testLayout/styled-components'
import { CourseStatusContainer } from '../styled-components'
import { TabNavigation, TabPanel, activeTabClassName } from 'components/common/Tabs/Tabs'
import AppBar from '@mui/material/AppBar'
import CourseGroups from '../EditCourseDrawer/Tab2'
import StudentsLayout from '../CourseStudents/StudentsLayout'
import TutorsLayout from '../CourseTutors/TutorsLayout'
import { WhiteAiAssistant } from 'suada-components'
import AIAssistant from '../AIAssistant'
import AIAssistantChat from '../AIAssistantChat'
import TextEditor from 'components/common/TextEditor/TextEditor'
import { useCourseDetails } from './hooks/useCourseDetails'

const CourseDetail = (): ReactElement => {
  const {
    breadCrumbData,
    course,
    isCompanyCourse,
    isCompanyPurchasedCourse,
    isAIAssistantEnabled,
    refetchCourse,
    selectedGroup,
    setSelectedGroup,
    videoModal,
    handleVideoModal,
    handleTabChange,
    value,
    actions,
    loading,
    t,
    getCourseState,
    state,
    CourseInformation,
  } = useCourseDetails()

  if (loading) return <MyLoader style={{ height: '83vh' }} speed={0.6} />

  return (
    <>
      <CourseDetailsWrapper>
        <Breadcrumb data={breadCrumbData} />
        {isCompanyPurchasedCourse && (
          <span
            style={{
              display: 'inline-block',
              maxWidth: '100%',
              whiteSpace: 'normal',
              color: '#FF3A52',
            }}
          >
            {t('courses_layout.suada_course_edit_permission_text')}
          </span>
        )}
        <ModulesContentWrapper>
          <CourseContentDetailsContainer>
            <ModuleContentDetailsHeader>
              <h3>
                <strong>{course.name}</strong>{' '}
              </h3>
              <ButtonsWrapper>
                <CourseStatusContainer $status={course.state === 'published'}>
                  {course.finished === 3 ? getCourseState(course.state) : t('courses_layout.draft')}
                </CourseStatusContainer>
                {isCompanyCourse && (
                  <AI_assistantWrapper
                    bgColor={isAIAssistantEnabled ? '#0074D9' : '#68707A'}
                    style={{ minWidth: '175px', textAlign: 'center' }}
                  >
                    <WhiteAiAssistant />{' '}
                    {isAIAssistantEnabled
                      ? t('actions.enabled_AI_assistant')
                      : t('actions.disabled_AI_assistant')}
                  </AI_assistantWrapper>
                )}
                {!isCompanyCourse && state.currentUser.companyId ? null : (
                  <ActionsWrapper>
                    <GridActionMenu actionConfig={actions} button={<MoreIcon />} row={course} />
                  </ActionsWrapper>
                )}
              </ButtonsWrapper>
            </ModuleContentDetailsHeader>

            <CourseContentDetailsContainerFlex>
              <StyledCourseDetailsLeftParent>
                <h4 style={styles.detailHeader}>{t('general.overview')}</h4>

                <StyledCourseDetailsLeft>
                  <TextEditor
                    isVisible={false}
                    text={course.editorText}
                    hasControls={false}
                    defaultText={t('default_texts.transcript')}
                  />
                </StyledCourseDetailsLeft>
              </StyledCourseDetailsLeftParent>
              <StyledCourseDetailsBetweenParent>
                <h4 style={styles.detailHeader}>{t('general.details')}</h4>

                <CourseContentDetails>
                  <StyledCourseDetailsFlex>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>{t('course_details.level')}</p>
                      {course.level || '-'}
                    </ModuleContentDetail>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>{t('course_details.duration')}</p>{' '}
                      {formatDuration(course.duration)}
                    </ModuleContentDetail>

                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>{t('course_details.certificate')}</p>
                      {course.certificateIncluded
                        ? t('general.included')
                        : t('general.not_included')}
                    </ModuleContentDetail>
                  </StyledCourseDetailsFlex>
                  <StyledCourseDetailsFlex>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>{t('general.students')}</p>
                      {course.numberOfStudents}
                    </ModuleContentDetail>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>{t('general.groups')}</p>
                      {course.groups.length}
                    </ModuleContentDetail>
                    <ModuleContentDetail>
                      <p style={styles.detailTitle}>{t('course_details.created_by')}:</p>
                      {course.companyId && !course.isBought ? (
                        <>{course.companyId.name}</>
                      ) : !isCompanyCourse ? (
                        <>SUADA</>
                      ) : (
                        <>Suada</>
                      )}
                    </ModuleContentDetail>
                  </StyledCourseDetailsFlex>
                </CourseContentDetails>
              </StyledCourseDetailsBetweenParent>
              <CourseContentDetailsRight>
                <StyledCourseDetailsSkillsParent>
                  <h4 style={styles.detailHeader}>{t('course_details.skills')}</h4>
                  {course.skills ? (
                    <TagsGrid>
                      <Tags tags={course?.skills} type='course' />
                    </TagsGrid>
                  ) : (
                    <CenteredText>{t('course_details.no_skills')}</CenteredText>
                  )}
                </StyledCourseDetailsSkillsParent>
              </CourseContentDetailsRight>
            </CourseContentDetailsContainerFlex>
          </CourseContentDetailsContainer>
        </ModulesContentWrapper>
        {/* CASE FOR PURCHASED COURSES */}
        {course.isBought ? (
          <TabNavigation>
            <AppBar position='static' style={styles.bar}>
              <Tabs value={value} onChange={handleTabChange} aria-label='simple tabs example'>
                <Tab
                  label={`${t('general.modules')}`}
                  {...activeTabClassName(0)}
                  style={styles.btn}
                />
                <Tab label={t('general.iq_tests')} {...activeTabClassName(1)} style={styles.btn} />
                <Tab label={t('general.groups')} {...activeTabClassName(2)} style={styles.btn} />
                <Tab label={t('general.students')} {...activeTabClassName(3)} style={styles.btn} />
                <Tab label={t('general.coaches')} {...activeTabClassName(4)} style={styles.btn} />
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant')}
                    {...activeTabClassName(5)}
                    style={styles.btn}
                  />
                )}
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant_chat')}
                    {...activeTabClassName(6)}
                    style={styles.btn}
                  />
                )}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} padding={0}>
              <ModulesTab course={course} refetchCourse={refetchCourse} />
            </TabPanel>

            <TabPanel value={value} index={1} padding={0}>
              <AssessmentsTab course={course} />
            </TabPanel>
            <TabPanel value={value} index={2} padding={0}>
              <CourseGroups
                courseData={{
                  courseId: course.id,
                  name: course.name,
                }}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                formFooterVisible={false}
              />
            </TabPanel>
            <TabPanel value={value} index={3} padding={0}>
              <StudentsLayout groups={selectedGroup} companyId={course.companyId?.id || ''} />
            </TabPanel>
            <TabPanel value={value} index={4} padding={0}>
              <TutorsLayout
                courseId={course.id}
                groups={selectedGroup}
                companyId={course.companyId?.id || ''}
              />
            </TabPanel>
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={5} padding={0}>
                <AIAssistant />
              </TabPanel>
            )}
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={6} padding={0}>
                <AIAssistantChat />
              </TabPanel>
            )}
          </TabNavigation>
        ) : isCompanyCourse ? (
          // CASE FOR COMPANY COURSES
          <TabNavigation>
            <AppBar position='static' style={styles.bar}>
              <Tabs value={value} onChange={handleTabChange} aria-label='simple tabs example'>
                <Tab
                  label={`${t('general.modules')}`}
                  {...activeTabClassName(0)}
                  style={styles.btn}
                />
                <Tab label={t('general.iq_tests')} {...activeTabClassName(1)} style={styles.btn} />
                <Tab label={t('general.groups')} {...activeTabClassName(2)} style={styles.btn} />
                <Tab label={t('general.students')} {...activeTabClassName(3)} style={styles.btn} />
                <Tab label={t('general.coaches')} {...activeTabClassName(4)} style={styles.btn} />
                {/* <Tab
                  label={t('general.information')}
                  {...activeTabClassName(5)}
                  style={styles.btn}
                /> */}
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant')}
                    {...activeTabClassName(5)}
                    style={styles.btn}
                  />
                )}
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant_chat')}
                    {...activeTabClassName(6)}
                    style={styles.btn}
                  />
                )}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} padding={0}>
              <ModulesTab course={course} refetchCourse={refetchCourse} />
            </TabPanel>

            <TabPanel value={value} index={1} padding={0}>
              <AssessmentsTab course={course} />
            </TabPanel>

            <TabPanel value={value} index={2} padding={0}>
              <CourseGroups
                courseData={{
                  courseId: course.id,
                  name: course.name,
                }}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                formFooterVisible={false}
              />
            </TabPanel>

            <TabPanel value={value} index={3} padding={0}>
              <StudentsLayout groups={selectedGroup} companyId={course.companyId?.id || ''} />
            </TabPanel>
            <TabPanel value={value} index={4} padding={0}>
              <TutorsLayout
                courseId={course.id}
                groups={selectedGroup}
                companyId={course.companyId?.id || ''}
              />
            </TabPanel>

            {isAIAssistantEnabled && (
              <TabPanel value={value} index={5} padding={0}>
                <AIAssistant />
              </TabPanel>
            )}
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={6} padding={0}>
                <AIAssistantChat />
              </TabPanel>
            )}
          </TabNavigation>
        ) : (
          // CASE FOR TEMPLATE COURSES
          <TabNavigation>
            <AppBar position='static' style={styles.bar}>
              <Tabs value={value} onChange={handleTabChange} aria-label='simple tabs example'>
                <Tab
                  label={`${t('general.modules')}`}
                  {...activeTabClassName(0)}
                  style={styles.btn}
                />
                <Tab label={t('general.iq_tests')} {...activeTabClassName(1)} style={styles.btn} />
                <Tab
                  label={t('general.information')}
                  {...activeTabClassName(2)}
                  style={styles.btn}
                />
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant')}
                    {...activeTabClassName(3)}
                    style={styles.btn}
                  />
                )}
                {isAIAssistantEnabled && (
                  <Tab
                    label={t('general.AI_assistant_chat')}
                    {...activeTabClassName(4)}
                    style={styles.btn}
                  />
                )}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0} padding={0}>
              <ModulesTab course={course} refetchCourse={refetchCourse} />
            </TabPanel>

            <TabPanel value={value} index={1} padding={0}>
              <AssessmentsTab course={course} />
            </TabPanel>
            <TabPanel value={value} index={2} padding={0}>
              <CourseInformation />
            </TabPanel>
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={3} padding={0}>
                <AIAssistant />
              </TabPanel>
            )}
            {isAIAssistantEnabled && (
              <TabPanel value={value} index={4} padding={0}>
                <AIAssistantChat />
              </TabPanel>
            )}
          </TabNavigation>
        )}
      </CourseDetailsWrapper>

      <Modal isOpened={videoModal.modal} onClose={handleVideoModal}>
        <Player
          url={videoModal.data?.url}
          thumbnail={videoModal.data?.thumbnail}
          subtitle={course.subtitle}
        />
      </Modal>
    </>
  )
}

export default CourseDetail
