import React, { ChangeEvent, FormEvent } from 'react'
import ReactSelect, { ValueType } from 'react-select'
import { useQuery } from '@apollo/client'
import { DrawerContent, Title } from 'components/DrawerLayout/styled-components'
import {
  FormContainer,
  FormGroupWrapper,
  FormFields,
  FormField,
  FormFooter,
  FormButtons,
} from 'components/common/Form'
import { Button } from 'components/common/Button'
import { useUserValue } from 'context/UserContext'
import { useFetchModules } from 'hooks/modules/useModules'
import { filterQuestionTypes, editedOptions } from 'pages/questions/staticData'
import { GET_TAGS } from 'gql/skilss.query'
import { LowerCaseFilterableFieldType } from 'enums/filterEnum'
import { ITags } from 'components/common/Tags/tags.interface'
import { IModule } from 'pages/modules/ModulesList.interface'
import { IFilterQueryType } from 'components/common/FilterInterface/filter.interface'
import { IQuestionsFilterData, IQuestionsFilterOption } from 'hooks/questions/useQuestionsLayout'
import { ISelectDropDownOption } from 'components/common/SelectDropDown/selectDropDown.interface'
import CustomDrawerHeader from 'components/common/CustomDrawerHeader'
import { IFilterDrawerProps } from './filterDrawer.interface'

const FilterDrawer = ({
  courseId,
  closeDrawer,
  handleFilterClick,
  filterData,
  quizModel,
  setFilterData,
  companyId,
  setIsFiltered,
  setCurrentPage,
  showStatusField = true,
  t,
}: IFilterDrawerProps): React.JSX.Element => {
  const [state] = useUserValue()

  const companyIdForTags = companyId || state.selectedCompany?.id

  const { data } = useQuery(GET_TAGS, {
    variables: {
      companyId: companyIdForTags,
    },
  })

  const filters: IFilterQueryType = {
    companyId: {
      type: LowerCaseFilterableFieldType.EXACT,
      value: state.selectedCompany?.id,
    },
  }

  if (quizModel === 'course' && courseId) {
    filters.course = { type: 'nestedArrayIn', value: courseId }
  }

  const { modules } = useFetchModules(filters, 1, 0)

  const tagList =
    (data && data.getAllTags.map((i: ITags) => ({ label: i.label, value: i.id }))) || []

  const moduleList =
    (modules && modules.data.map((i: IModule) => ({ label: i.name, value: i.id }))) || []

  const handleChange = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement> | ValueType<ITags, true>,
  ): void => {
    if (e === null || !(e as ChangeEvent).target) {
      setFilterData({
        ...filterData,
        [field]: e,
      } as IQuestionsFilterData)
    } else {
      setFilterData({
        ...filterData,
        [field]: (e as ChangeEvent<HTMLInputElement>).target?.value,
      })
    }
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()

    const filterOptions: IQuestionsFilterOption = {}
    if (filterData.type && (filterData.type as ISelectDropDownOption).value !== 'all') {
      filterOptions.type = {
        type: LowerCaseFilterableFieldType.EXACT,
        value: (filterData.type as ISelectDropDownOption).value as string[],
        nestedField: 'value',
      }
    }

    if (filterData.tags) {
      filterOptions.tags = {
        type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
        value: filterData.tags.map((tag: ITags) => tag.label),
        nestedField: 'label',
      }
    }

    if (filterData.modules) {
      filterOptions.modules = {
        type: LowerCaseFilterableFieldType.NESTED_ARRAY_IN,
        value: filterData.modules.map((moduleItem: ITags) => moduleItem.value) as string[],
        nestedField: 'moduleId',
      }
    }

    if (filterData.edited && (filterData.edited as ISelectDropDownOption).value !== 'all') {
      filterOptions.edited = {
        type: LowerCaseFilterableFieldType.BOOL,
        value: (filterData.edited as ISelectDropDownOption).value as string[],
      }
    }

    if (handleFilterClick) handleFilterClick(filterOptions)

    closeDrawer()
    if (setCurrentPage) setCurrentPage(1)
    if (setIsFiltered) {
      setIsFiltered(JSON.stringify(filterOptions) !== '{}')
    }
  }
  return (
    <>
      <CustomDrawerHeader title={t('actions.filters')} onClose={closeDrawer} />
      <DrawerContent>
        <FormContainer onSubmit={handleSubmit}>
          <FormGroupWrapper>
            <FormFields>
              <FormField>
                <Title>{t('general.type')}</Title>
                <ReactSelect
                  className='select-type'
                  onChange={(e): void => handleChange('type', e as ValueType<ITags, true>)}
                  options={filterQuestionTypes}
                  value={
                    (filterData.type as ISelectDropDownOption) || {
                      value: 'all',
                      label: 'All',
                    }
                  }
                  placeholder={t('form_fields.select')}
                />
              </FormField>

              <FormField>
                <Title>{t('general.tags')}</Title>
                <ReactSelect
                  className='select-tags'
                  onChange={(e): void => handleChange('tags', e as ValueType<ITags, true>)}
                  options={tagList}
                  value={filterData.tags}
                  isMulti
                  placeholder={t('form_fields.select')}
                />
              </FormField>
              {(quizModel === 'general' || quizModel === 'course') && (
                <FormField>
                  <Title>{t('general.modules')}</Title>
                  <ReactSelect
                    className='select-modules'
                    onChange={(e): void => handleChange('modules', e)}
                    options={moduleList}
                    value={filterData.modules}
                    isMulti
                  />
                </FormField>
              )}
              {showStatusField && (
                <FormField>
                  <Title>{t('general.status')}</Title>
                  <ReactSelect
                    className='select-status'
                    onChange={(e): void => handleChange('edited', e as ValueType<ITags, true>)}
                    options={editedOptions}
                    value={
                      (filterData.edited as ISelectDropDownOption) || {
                        value: 'all',
                        label: 'All',
                      }
                    }
                  />
                </FormField>
              )}
            </FormFields>
          </FormGroupWrapper>
          <FormFooter>
            <FormButtons>
              <Button
                className='filter-button'
                text={t('actions.filter')}
                type='small'
                color='secondary'
                btnType='submit'
                background='#06C68F'
              />
              <Button
                text={t('actions.cancel')}
                type='small'
                onClick={closeDrawer}
                background='#E0E1E2'
                textColor='#414141'
              />
            </FormButtons>
          </FormFooter>
        </FormContainer>
      </DrawerContent>
    </>
  )
}

export default FilterDrawer
