import React from 'react'
import { Action } from 'components/common/GridV2'
import { TrashCanRed } from 'suada-components'
import { EditSVG } from 'suada-components'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { TFunction } from 'interfaces/TFunction'

interface ActionItem {
  render: () => JSX.Element
  hide?: boolean
}

export const actionConfig = (
  handleEditClick: () => void,
  handleDeleteClick: () => void,
  t: TFunction,
): ActionItem[] => {
  const action: ActionItem[] = [
    {
      render: () => (
        <Action onClick={handleEditClick}>
          <EditSVG /> <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      render: () => (
        <Action onClick={handleDeleteClick}>
          <TrashCanRed /> <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: ActionItem) => !i.hide)
}
