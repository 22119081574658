import React from 'react'

import { IPersonalInformation } from 'pages/profile/AdminProfile/PersonalInformation.interface'

import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'

import { DarkTrashCan } from 'suada-components'
import { EditUsers } from 'suada-components'
import { ReactComponent as StatsIcon } from 'components/common/Button/icons/chart-stepper.svg'
import { CloseOutline } from 'suada-components'
import { t } from 'i18next'

interface IUsersAction {
  icon: JSX.Element
  text: string
  onClick: () => void
}

export interface IUserInfoRow {
  subtitle: string
  info: string
}

export const getInfoItemsRow = (
  profileData: IPersonalInformation,
  isCompany: boolean,
): IUserInfoRow[] => [
  {
    subtitle: t('user_details.member_since'),
    info: profileData.createDate
      ? new Date(profileData.createDate).toLocaleDateString('en-GB')
      : 'N/A',
  },
  {
    subtitle: t('user_details.email_address'),
    info: profileData.email || 'N/A',
  },
  {
    subtitle: t('user_details.phone'),
    info: profileData.phoneFields?.code
      ? `${profileData.phoneFields.code} ${profileData.phone}`
      : 'N/A',
  },
  {
    subtitle: t('form_fields.gender'),
    info: profileData.gender || 'N/A',
  },
  {
    subtitle: t('form_fields.birth_date'),
    info: profileData.birthDate
      ? new Date(parseInt(profileData.birthDate.toString())).toLocaleDateString('en-GB')
      : 'N/A',
  },
  {
    subtitle: t('user_details.age'),
    info: profileData.age?.toString() || 'N/A',
  },
  {
    subtitle: t('form_fields.job_title'),
    info: profileData.jobTitle || 'N/A',
  },
  {
    subtitle: t('general.company'),
    info: (!isCompany ? 'SUADA' : profileData?.companyId?.name) || 'N/A',
  },
  {
    subtitle: t('form_fields.location'),
    info: profileData.location || 'N/A',
  },
]

export const getActions = (
  profileData: IPersonalInformation,
  handleEditClick: () => void,
  handleDeleteClick: (user: IPersonalInformation) => void,
  handleActivityClick: (userId: string[]) => void,
  handleChangeStatusClick: (userId: string, status: string) => void,
): IUsersAction[] => [
  {
    icon: <EditUsers />,
    text: t('actions.edit'),
    onClick: handleEditClick,
  },
  {
    icon: <DarkTrashCan />,
    text: t('actions.delete'),
    onClick: () => handleDeleteClick(profileData),
  },
  {
    icon: <StatsIcon />,
    text: t('actions.activity'),
    onClick: () => handleActivityClick([profileData.id || '']),
  },
  {
    icon:
      profileData.status === 'ACTIVE' ? (
        <CloseOutline />
      ) : (
        <CheckCircleOutlineOutlinedIcon style={{ width: '18px' }} />
      ),
    text: profileData.status === 'ACTIVE' ? t('actions.suspend') : t('actions.activate'),
    onClick: () =>
      handleChangeStatusClick(
        profileData.id || '',
        profileData.status === 'ACTIVE' ? 'ACTIVE' : 'SUSPENDED',
      ),
  },
]
