import moment from 'moment'
import React from 'react'
import {
  IActionConfig,
  IColumnConfig,
  IGridActionItem,
  ITagsColumnConfig,
  Item,
} from './TasksLayout.interface'
import { CheckBoxAndAvatarContainer } from 'components/UsersLayout/styled-components'
import { Tooltip } from '@mui/material'
import { TrashCanAction } from 'suada-components'
import { EditRounded } from 'suada-components'
import Checkbox from 'components/common/Checkbox'
import { Action, StyledTagName } from './styled-components'
import { TFunction } from 'interfaces/TFunction'
import { ITask, ITasksGroupedByTag } from 'services/tasks/getTasksService.interface'
import Tags from 'components/common/Tags/Tags'

export const columnConfig = (
  handleTaskSelection: (id: string) => void,
  selectedTasks: string[],
  t: (key: string) => string,
  showAdditionalFields: boolean,
): IColumnConfig[] => {
  const config = [
    {
      id: 'isChecked',
      label: '',
      render: (item: Item): JSX.Element => (
        <CheckBoxAndAvatarContainer>
          <Checkbox
            checked={selectedTasks.includes(item._id)}
            onChange={(): void => handleTaskSelection(item._id)}
          />{' '}
        </CheckBoxAndAvatarContainer>
      ),
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'name',
      label: t('general.name'),
      render: (item: Item): JSX.Element => <span> {item.name}</span>,
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'tags',
      label: t('general.tags'),
      render: (item: Item): JSX.Element => <Tags tags={item.tags || []} />,
      numeric: false,
      disablePadding: true,
    },
    ...(showAdditionalFields
      ? [
          {
            id: 'studentCount',
            label: t('general.students'),
            render: (item: Item): JSX.Element => <span> {item.studentsCount}</span>,
            numeric: false,
            disablePadding: false,
          },
        ]
      : []),
    {
      id: 'date',
      label: t('general.create_date'),
      render: (item: Item): JSX.Element => (
        <span>{moment(item.createDate).format('DD-MM-YYYY')}</span>
      ),
      numeric: false,
      disablePadding: false,
    },
  ]
  return config
}

export const tagsColumnConfig = (t: TFunction): ITagsColumnConfig[] => {
  const config = [
    {
      id: 'name',
      label: t('general.tag_name'),
      render: (item: ITasksGroupedByTag): JSX.Element => (
        <StyledTagName>{item.tag || t('tasks_layout.tasks_without_tags')}</StyledTagName>
      ),
      numeric: false,
      disablePadding: false,
    },
  ]

  return config
}

export const actionConfig = (
  handleDeleteClick: (id: string) => void,
  handleEditClick: (id: string) => void,
  t: (key: string) => string,
  setIsSearchType?: (value: boolean) => void,
): IActionConfig[] => {
  const actions = [
    {
      id: 'edit',
      // hide: !permissions[`edit${field}`],
      render: (item: IGridActionItem): JSX.Element => (
        <Action
          className='edit'
          hoverColor='#5aa4f5'
          hoverBackground='#eff6fe'
          onClick={(): void => handleEditClick(item._id)}
        >
          <Tooltip title={t('actions.edit')} arrow>
            <EditRounded />
          </Tooltip>
        </Action>
      ),
    },

    {
      // hide: !permissions[`delete${field}`],
      id: 'delete',
      render: (item: IGridActionItem): JSX.Element => (
        <Action
          className='delete'
          hoverColor='#ea382a'
          hoverBackground='#fdefee'
          onClick={(): void => {
            handleDeleteClick(item._id)
            if (setIsSearchType) {
              setIsSearchType(false)
            }
          }}
        >
          <Tooltip title={t('actions.delete')} arrow>
            <TrashCanAction />
          </Tooltip>
        </Action>
      ),
    },
  ]
  return actions
}

export const testTabActionConfig = (
  handleDeleteTaskFromGroup: (id: string) => void,
  t: (key: string) => string,
): IActionConfig[] => {
  const actions = [
    {
      id: 'delete',
      render: (item: IGridActionItem): JSX.Element => (
        <Action
          className='delete'
          hoverColor='#ea382a'
          hoverBackground='#fdefee'
          onClick={(): void => {
            handleDeleteTaskFromGroup(item._id)
          }}
        >
          <Tooltip title={t('actions.delete')} arrow>
            <TrashCanAction />
          </Tooltip>
        </Action>
      ),
    },
  ]
  return actions
}
export const getCollapsedData = (item: ITasksGroupedByTag): ITask[] => {
  if (!item) return []

  return item.tasks
}
