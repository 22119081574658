import React from 'react'
import { EditSVG } from 'suada-components'
import { DarkAiAssistent } from 'suada-components'
import { TrashCanRed } from 'suada-components'
import { DownloadSVG } from 'suada-components'
import { ReactComponent as PlayButton } from 'assets/play-icon.svg'
import { Reset } from 'suada-components'
import { Action } from 'components/common/GridV2'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { UnlockSVG } from 'suada-components'
import { IItem } from './interfaces'
import { IUserState } from 'store/reducers/userReducer'
import { IAction } from 'components/common/GridV2/GridActionMenu/gridActionMenu.interface'

export const actionConfig = (
  handleEditClick: (id: string) => void,
  handleDeleteClick: (id: string) => void,
  t: (key: string) => string,
  getCourseState: (state: string) => string,
  state: IUserState,
  handlePublishClick: (id: string, state: string) => void,
  handleAIAssistantClick: (id: string) => void,
  isAIAssistantEnabled: boolean,
  isCompanyCourse: boolean,
  handlePlayIntroClick: (item: IItem) => void,
  handleDownloadClick: (item: IItem) => void,
  hasIntro: boolean,
  handleReTrainAIAssistantClick: () => void,
  hasAssistant: boolean,
  isCompanyPurchasedCourse?: string,
): IAction[] => {
  if (isCompanyPurchasedCourse) {
    return [
      {
        render: (): JSX.Element => (
          <Action disabled>
            <EditSVG />
            <span>{t('courses_layout.suada_course_edit_permission')}</span>
          </Action>
        ),
      },
      {
        hide: !state.currentUser?.isSuperAdmin,
        render: (item: IItem): JSX.Element => (
          <Action
            onClick={(): void => {
              handleAIAssistantClick(item.id)
            }}
          >
            <DarkAiAssistent />
            <span>
              {isAIAssistantEnabled
                ? t('actions.disable_AI_assistant')
                : t('actions.enable_AI_assistant')}
            </span>
          </Action>
        ),
      },
      {
        hide: !state.currentUser?.isSuperAdmin && !hasAssistant,
        render: (): JSX.Element => (
          <Action onClick={handleReTrainAIAssistantClick}>
            <Reset />
            <span>{t('AI_assistant.re_train_assistant')}</span>
          </Action>
        ),
      },
    ]
  }
  const action = [
    {
      render: (item: IItem): JSX.Element => (
        <Action
          onClick={(): void => {
            handleEditClick(item.id)
          }}
        >
          <EditSVG />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !isCompanyCourse,
      render: (item: IItem): JSX.Element => (
        <Action
          onClick={(): void => {
            handleAIAssistantClick(item.id)
          }}
        >
          <DarkAiAssistent />
          <span>
            {isAIAssistantEnabled
              ? t('actions.disable_AI_assistant')
              : t('actions.enable_AI_assistant')}
          </span>
        </Action>
      ),
    },
    {
      hide: !(isCompanyCourse && hasAssistant),
      render: (): JSX.Element => (
        <Action onClick={handleReTrainAIAssistantClick}>
          <Reset />
          <span>{t('AI_assistant.re_train_assistant')}</span>
        </Action>
      ),
    },
    {
      hide: !state.userPermission.publishCourse,
      render: (item: IItem): JSX.Element => (
        <Action
          onClick={(): void =>
            handlePublishClick(item.id, item.state === 'published' ? 'unpublished' : 'published')
          }
        >
          <UnlockSVG />
          <span>{getCourseState(item.state || '')}</span>
        </Action>
      ),
    },
    {
      hide: !hasIntro,
      render: (item: IItem): JSX.Element => (
        <Action onClick={(): void => handleDownloadClick(item)}>
          <DownloadSVG />
          <span>{t('actions.download_intro')}</span>
        </Action>
      ),
    },
    {
      hide: !hasIntro,
      render: (item: IItem): JSX.Element => (
        <Action onClick={(): void => handlePlayIntroClick(item)} disabled={!item.video}>
          <PlayButton />
          <span>{t('actions.play_intro')}</span>
        </Action>
      ),
    },
    {
      render: (item: IItem): JSX.Element => (
        <Action onClick={(): void => handleDeleteClick(item.id)}>
          <TrashCanRed />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter((i: IAction) => !i.hide)
}
