import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import {
  Root,
  StyledHeaderContainer,
  StyledListContainer,
  StyledListLastItem,
  Toolbar,
  ToolbarActions,
} from './styled-components'
import { Button } from 'components/common/Button'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import AddCircleRoundedIcon from '@mui/icons-material/Add'
import { TrashCanAction } from 'suada-components'
import Modal from 'components/common/Modal'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import { RemoveFilter } from 'suada-components'
import { FilterIcon } from 'suada-components'
import { NewFilter } from 'suada-components'
import Player from 'components/common/VideoPlayer/Player'
import TextInput from 'components/common/TextInput/TextInput'
import { MultipleAction } from 'components/common/Grid/styled-components'
import AudioPlayer from 'components/common/AudioPlayer'
import Drawer from 'components/common/Drawer'
import { FilterDrawer } from 'pages/topics'
import NestedGridV2 from './NestedGrid'
import { ActionItem, IConfigItem, IModuleTopicsGridV2Props } from './ModuleTopic.interface'
import { SplashScreen } from 'components/common'
import useModuleTopicsGridV2 from 'hooks/modules/ModuleTopicGridV2/useModuleTopicGridV2'
import { IAction } from 'components/common/GridV2'
import MyLoader from 'loading/loading'
import Tooltip from '@mui/material/Tooltip'

const ModuleTopicsGridV2 = ({
  data,
  loadingTopics,
  canModify,
  routerState,
  t,
  moduleId,
  refetch,
}: IModuleTopicsGridV2Props): React.JSX.Element => {
  const {
    cards,
    createButton,
    filterDrawerOpened,
    setFilterDrawerOpened,
    filterData,
    setFilterData,
    filter,
    setFiltered,
    state,
    handleDrawerOpen,
    selectedItem,
    handleDeleteClick,
    filtered,
    handleResetClick,
    searchValue,
    handleSearchChange,
    config,
    filteredData,
    actions,
    nestedConfig,
    selectAllItem,
    changeTopicsOrder,
    moveCard,
    nestedAction,
    setCollapsedItems,
    collapsedItems,
    onCollapseClick,
    mediaModal,
    handleOpenMediaModal,
    maxValues,
  } = useModuleTopicsGridV2({
    data,
    loadingTopics,
    canModify,
    routerState,
    t,
    moduleId,
    refetch,
  })

  if (!cards?.length && searchValue === '' && !loadingTopics) {
    return (
      <>
        <SplashScreen
          title={t('lessons_layout.no_lessons')}
          subTitle={t('lessons_layout.create_lessons_information')}
          createButton={createButton}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      </>
    )
  }

  return (
    <Root>
      <Drawer opened={filterDrawerOpened} toggleDrawer={(): void => undefined}>
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawerOpened(false)}
          filterData={filterData}
          setFilterData={setFilterData}
          filter={filter}
          setFiltered={setFiltered}
          t={t}
          maxValues={maxValues}
        />
      </Drawer>
      <Toolbar>
        <ToolbarActions>
          {state.userPermission.createLesson && canModify && (
            <Button
              text={t('lessons_layout.create_lesson')}
              size='small'
              color='secondary'
              onClick={(): void => handleDrawerOpen('addLesson')}
              icon={<AddCircleRoundedIcon />}
              background='#06C68F'
            />
          )}
          {canModify && (
            <GridMultipleActions
              selectedItems={selectedItem.length}
              actions={[
                {
                  id: 0,
                  color: 'secondary',
                  hide: !state.userPermission.deleteTopic,
                  tooltipText: `${t('actions.delete')}`,
                  disabled: selectedItem.length < 1,
                  onClick: () => handleDeleteClick(selectedItem),
                  component: <TrashCanAction fontSize='small' />,
                },
              ].filter((i: ActionItem) => !i.hide)}
            />
          )}
        </ToolbarActions>
        <div style={{ display: 'flex' }}>
          <MultipleAction>
            <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
              <Tooltip title={t('actions.filter')} arrow>
                {filtered ? <NewFilter /> : <FilterIcon />}
              </Tooltip>
            </IconButton>
          </MultipleAction>
          <MultipleAction>
            {filtered && (
              <IconButton onClick={handleResetClick}>
                <Tooltip title={t('actions.remove_filter')} arrow>
                  <RemoveFilter />
                </Tooltip>
              </IconButton>
            )}
          </MultipleAction>
          <form style={{ marginLeft: 10 }}>
            <TextInput
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon />
                </IconButton>
              }
            />
          </form>
        </div>
      </Toolbar>
      {!loadingTopics &&
      cards &&
      (cards.length === 0 || (filtered && (filteredData?.length ?? 0) === 0)) ? (
        <SplashScreen
          title={t('general.no_results_found')}
          subTitle={t('general.try_adjusting_your_search_or_filter')}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      ) : (
        <>
          <StyledListContainer>
            <List>
              <StyledHeaderContainer>
                <ListItem>
                  <span style={{ width: '720px' }}>Name </span>
                </ListItem>
                <ListItem>
                  <span>Lessons</span>
                </ListItem>
                <ListItem>Duration</ListItem>
                <StyledListLastItem>Actions</StyledListLastItem>
              </StyledHeaderContainer>
            </List>
          </StyledListContainer>

          {loadingTopics ? (
            <div>
              <MyLoader />
            </div>
          ) : (
            cards && (
              <NestedGridV2
                config={config}
                data={filteredData ? filteredData : cards}
                actions={canModify ? (actions as IAction[]) : []}
                nestedAction={canModify ? (nestedAction as IConfigItem[]) : []}
                nestedConfig={nestedConfig as IConfigItem[]}
                selectAll={selectAllItem}
                selectedItems={selectedItem}
                loading={loadingTopics}
                changeTopicsOrder={changeTopicsOrder}
                moveCard={moveCard}
                canModify={canModify}
                routerState={routerState}
                t={t}
                noRecordsDefaultText={t('default_texts.topics_and_lessons')}
                setCollapsedItems={setCollapsedItems}
                collapsedItems={collapsedItems}
                onCollapseClick={onCollapseClick}
              />
            )
          )}
        </>
      )}

      <Modal isOpened={mediaModal.modal} onClose={handleOpenMediaModal}>
        <div>
          {mediaModal.field === 'playAudio' && <AudioPlayer url={mediaModal.url} />}
          {mediaModal.field === 'playVideo' && (
            <Player url={mediaModal.url} subtitle={mediaModal.subtitle} />
          )}
        </div>
      </Modal>
    </Root>
  )
}

export default ModuleTopicsGridV2
