import React, { ReactElement } from 'react'
import _ from 'lodash'
import { GridToolbar, GridLayoutActions } from 'components/common/Grid/styled-components'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import Loader from 'components/common/Loader'
import Drawer from 'components/common/Drawer'
import TextInput from 'components/common/TextInput/TextInput'
import Grid from 'components/common/GridV2'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions/GridMultipleActions'
import { FilterCoaches } from 'pages/coaches'
import ResetUsersPassword from 'pages/profile/AllUserProfile/ResetPassword'
import Modal from 'components/common/Modal'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import { FlexComponent, LayoutWrapper, StyledPagination } from './styled-components'
import { ToolbarItem } from 'components/common/Toolbar'
import { FilterIcon } from 'suada-components'
import { RemoveFilter } from 'suada-components'
import Breadcrumb from 'components/common/BreadCrumbV2'
import PaginationV2 from 'components/common/PaginationV2'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'
import useCoachesLayout from 'hooks/coaches/useCoachesLayout'
import { NewFilter } from 'suada-components'
import { SplashScreen } from 'components/common'
import { StudentsActionsContainer } from 'components/common/GridV2/styled-components'
import { IUserCompany } from 'interfaces/users'
import MyLoader from 'loading/loading'
import Tooltip from '@mui/material/Tooltip'
import { LoaderContainer } from 'pages/tests/testDetails/studentsLayout/styled-components'

const CoachesLayout = (): ReactElement => {
  const {
    breadCrumbData,
    t,
    filterDrawerOpened,
    setFilterDrawerOpened,
    filter,
    loading,
    setFilterOptions,
    filterOptions,
    state,
    createButton,
    selectedItem,
    currentPage,
    multiActions,
    // setFilterDrawerOpened,
    searchValue,
    handleSearchChange,
    deleteUserLoading,
    updateUserStatusLoading,
    resetVisible,
    handleClick,
    config,
    users,
    actions,
    selectAllItem,
    selectItem,
    perPage,
    handleChangeRowsPerPage,
    handlePaginationClick,
    isResetPasswordOpen,
    userId,
    pushNotificationModal,
    handleModalClose,
    messageData,
    setMessageData,
    handlePushNotificationSend,
    setResetPasswordOpen,
    isFiltered,
    companyId,
    isSearchType,
    resetFilter,
  } = useCoachesLayout({})

  if (
    // !loading &&
    users &&
    companyId &&
    !searchValue &&
    !isSearchType &&
    !filterOptions &&
    users?.data.length === 0
  ) {
    return (
      <>
        <Breadcrumb data={breadCrumbData} />
        <SplashScreen
          title={t('user_details.no_tutors')}
          subTitle={t('user_details.create_tutors')}
          createButton={createButton}
        />
      </>
    )
  }

  return (
    <>
      <Breadcrumb data={breadCrumbData} />
      <LayoutWrapper>
        <Drawer opened={filterDrawerOpened} toggleDrawer={(): void => undefined} totalWidth='600px'>
          <FilterCoaches
            role='COACH'
            closeDrawer={(): void => setFilterDrawerOpened(false)}
            filter={filter}
            filterLoading={loading}
            setFilterOptions={setFilterOptions}
            filterOptions={filterOptions}
            hasSearchByCompany
            manualCompanyId={(state?.currentUser?.companyId as IUserCompany)?.id}
            useCompanyId={!!(state?.currentUser?.companyId && state.currentUser.companyId !== null)}
          />
        </Drawer>
        <GridToolbar>
          <GridLayoutActions>
            <div>{createButton()}</div>
            <GridMultipleActions
              selectedItems={_.sumBy(Object.values(selectedItem), 'length')}
              actions={multiActions()}
            />
          </GridLayoutActions>
          <FlexComponent>
            <ToolbarItem $noGap>
              <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                <Tooltip title={t('actions.filter')} arrow>
                  {isFiltered ? <NewFilter /> : <FilterIcon />}
                </Tooltip>
              </IconButton>
              {isFiltered && (
                <IconButton onClick={resetFilter}>
                  <Tooltip title={t('actions.remove_filter')} arrow>
                    <RemoveFilter />
                  </Tooltip>
                </IconButton>
              )}
            </ToolbarItem>
            <TextInput
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchValue}
              onChange={handleSearchChange}
              icon={
                <IconButton type='submit'>
                  <SearchRoundedIcon />
                </IconButton>
              }
              $iconInStart
              name='search'
            />
          </FlexComponent>
        </GridToolbar>
        <div>
          {(deleteUserLoading || updateUserStatusLoading) && <Loader />}
          {loading && !users ? (
            <LoaderContainer>
              <MyLoader />
            </LoaderContainer>
          ) : (
            users && (
              <>
                {users.data.length === 0 && (searchValue !== '' || isFiltered) ? (
                  <SplashScreen
                    title={t('general.no_results_found')}
                    subTitle={t('general.try_adjusting_your_search_or_filter')}
                    textMarginTop='40px'
                    isSearchOrFilter={true}
                  />
                ) : (
                  <>
                    {users.data.length > 0 ? (
                      <>
                        <Grid
                          resetVisible={resetVisible}
                          redirectToDetailsHandler={handleClick}
                          config={config}
                          actionTilesLength={3}
                          data={users.data}
                          actionConfig={actions}
                          selected={_.size(selectedItem[currentPage])}
                          selectAllItem={selectAllItem}
                          selectItem={selectItem}
                          selectedItems={selectedItem[currentPage] || []}
                          ActionsContainer={StudentsActionsContainer}
                        />
                        {users && users.data && (
                          <StyledPagination>
                            <PerPageDropdown
                              value={perPage}
                              onChange={(value): void =>
                                handleChangeRowsPerPage({
                                  target: {
                                    value: value.toString(),
                                  } as HTMLInputElement,
                                } as React.ChangeEvent<HTMLInputElement>)
                              }
                              options={[10, 20, 50]}
                            />
                            <PaginationV2
                              currentPage={currentPage}
                              totalPages={users?.totalPages || 0}
                              handleChange={handlePaginationClick}
                            />
                          </StyledPagination>
                        )}
                      </>
                    ) : (
                      <MyLoader />
                    )}
                  </>
                )}
              </>
            )
          )}
          {isResetPasswordOpen && (
            <ResetUsersPassword
              isModalOpen={isResetPasswordOpen}
              setModalOpen={setResetPasswordOpen}
              userId={userId}
            />
          )}
        </div>
        <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
          <div>
            <SendPushNotification
              messageData={messageData}
              setMessageData={setMessageData}
              onClose={handleModalClose}
              onSend={handlePushNotificationSend}
            />
          </div>
        </Modal>
      </LayoutWrapper>
    </>
  )
}

export default CoachesLayout
