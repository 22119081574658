import React, { ReactElement } from 'react'
import {
  Card,
  Header,
  IconWrapper,
  Subtitle,
  Title,
  Wrapper,
  DateWrapper,
} from './styled-components'
import CheckBox from 'components/common/Checkbox'
import { IGroupItemProps } from './GroupItem.interface'
import Tooltip from '@mui/material/Tooltip'
import { t } from 'i18next'

import { GroupDefault } from 'suada-components'
import { BookWithBookmark } from 'suada-components'
import { EducationDefault } from 'suada-components'

const formatDate = (timestamp: number): string => {
  const date = new Date(timestamp)
  const formattedDate = date.toLocaleDateString('en-GB')
  return formattedDate
}

const GroupItem = ({ item, selectItem, isChecked }: IGroupItemProps): ReactElement => {
  const { name, admin, courses, createDate, numberOfStudents } = item
  const formattedCreateDate = formatDate(createDate as number)

  const courseNames = courses?.map((course: { name: string }) => course.name).join(', ')
  const adminNames = admin?.map((admin: { firstName: string }) => admin.firstName).join(', ')

  return (
    <Card isChecked={isChecked} onClick={(): void => selectItem(item.id as string)}>
      <Header>
        <Title> {name}</Title>
        <CheckBox onChange={(): void => selectItem(item.id as string)} checked={isChecked} />
      </Header>
      <Wrapper>
        <Subtitle> {t('general.admins')} </Subtitle>
        <Tooltip title={adminNames} placement='bottom'>
          <IconWrapper>
            <GroupDefault />
            {admin?.length}
          </IconWrapper>
        </Tooltip>
      </Wrapper>
      <Wrapper>
        <Subtitle> {t('general.courses')} </Subtitle>
        <Tooltip title={courseNames} placement='bottom'>
          <IconWrapper>
            <BookWithBookmark />
            {courses?.length}
          </IconWrapper>
        </Tooltip>
      </Wrapper>
      <Wrapper>
        <Subtitle>{t('general.students')} </Subtitle>
        <IconWrapper>
          <EducationDefault />
          {numberOfStudents}
        </IconWrapper>
      </Wrapper>
      <Wrapper>
        <Subtitle>Creation Date:</Subtitle>
        <DateWrapper>{formattedCreateDate} </DateWrapper>
      </Wrapper>
    </Card>
  )
}

export default GroupItem
