import { ApolloQueryResult, OperationVariables } from '@apollo/client'
import { IAttachment } from 'interfaces/common'
import { IGroup } from 'interfaces/groups'

export enum GetTestsByGroupFiltersByEnums {
  IN_GROUP = 'IN_GROUP',
  NOT_IN_GROUP = 'NOT_IN_GROUP',
}

export interface IUseFetchTestAssessmentsByGroupProps {
  searchValue?: string
  currentPage?: number
  filterBy: GetTestsByGroupFiltersByEnums
  perPage?: number
  groupId?: string
}

export interface IUseFetchTestAssessmentsResponse {
  tests: ITestAssessment[]
  totalPages?: number
  loading: boolean
  error: string
  refetch: (
    variables?: Partial<OperationVariables> | undefined,
  ) => Promise<ApolloQueryResult<ITestAssessment>>
  fetchMore: (variables?: IFetchMoreVariables) => void
}

export interface IFetchMoreVariables {
  filter?: ITestFilter
  currentPage?: number
  perPage?: number
  companyId?: string
}

export interface ITestAssessment {
  _id?: string
  id?: string
  title?: string
  name?: string
  description?: string
  createDate?: string
  status?: string
  video: IAttachment | string | null
  audio: IAttachment | string | null
  attachment?: IAttachment | undefined
  groupIds?: IGroup[] | (string | undefined)[]
}

export interface ITestFilter {
  status?: string
  assignedTo?: string
  name?: string
}

export interface IAddTestToGroupResponse {
  addTest: (variables: ITestToGroupDto) => void
  tests: ITestAssessment[] | null
  loading: boolean
  error: string
}

export interface ITestToGroupDto {
  testIds?: string[]
  testAssessmentIds?: string[]
  groupId?: string
}

export interface IFetchTestsByCompanyIdProps {
  companyId?: string
  groupId?: string
}

export interface ITestsResponse {
  data: ITestAssessment[] | null
  totalPages: number
}

export interface IRemoveTestToGroupResponse {
  removeTestsFromGroup: (removeTestDto: ITestToGroupDto) => void
  tests: ITestAssessment[] | null
  loading: boolean
  error: string
}
