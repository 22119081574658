import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchTaskById } from 'services/tasks/getTaskByIdService'
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined'
import { useTranslation } from 'react-i18next'
import { useFetchStudentsByTaskId } from 'services/tasks/getStudentsByTaskIdService'
import {
  columnConfig,
  actionsConfig,
  studentsActionConfig,
} from 'pages/tasks/TaskLayout/gridConfig'
import { useDeleteTask } from 'services/tasks/deleteTaskService'
import useTaskLayout from 'hooks/tasks/useTaskLayout'
import { useSwal } from 'hooks/useSwal'
import { useUserValue } from 'context/UserContext'
import { useFetchTaskComments } from 'services/tasks/getTaskCommentsService'
import { GetGroupsByTaskFiltersEnums } from 'services/tasks/getTasksService.interface'
import { useFetchGroupsByTask } from 'services/tasks/getGroupsByTaskId'
import { styles } from 'pages/tasks/TaskLayout/styled-component'
import { IUseTaskDetailsReturn } from './useTaskDetails.interface'

const useTaskDetails = (): IUseTaskDetailsReturn => {
  const { taskId } = useParams()
  const [tab, setTab] = useState(0)
  const { t } = useTranslation()
  const [taskDrawerOpened, setTaskDrawerOpened] = useState<boolean>(false)
  const [taskCommentsDrawerOpened, setTaskCommentsDrawerOpened] = useState<boolean>(false)
  const [taskIdComment, setTaskIdComment] = useState<string>('')
  const { task, taskRefetch } = useFetchTaskById(taskId)
  const { id: companyIdFromUrl } = useParams()

  const [state] = useUserValue()

  const companyId = companyIdFromUrl
    ? companyIdFromUrl
    : state.selectedCompany
    ? state.selectedCompany.id
    : null

  const { mappedCourses, mappedGroups, loadingCourses, groupsLoading, setCurrentTaskId } =
    useTaskLayout(companyId)

  const { name, description, createDate, attachment } = task || {}
  const { fireSwal } = useSwal()
  const navigate = useNavigate()

  const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: number): void => {
    setTab(newValue)
  }

  const tabs = [
    { label: t('navigation.groups'), id: '1' },
    { label: t('navigation.students'), id: '2' },
  ]

  const { groups, totalCount, loading, refetch } = useFetchGroupsByTask({
    taskId,
    groupInclusion: GetGroupsByTaskFiltersEnums.IN_GROUP,
  })

  const { students } = useFetchStudentsByTaskId(taskId)
  const { deleteTask } = useDeleteTask()

  const { taskComments, loading: taskCommentsLoading } = useFetchTaskComments(taskIdComment || '')

  const handleDeleteClick = async (): Promise<void> => {
    const taskIdArray = [taskId].filter(id => id !== undefined) as string[]

    fireSwal({
      title: t('tasks_layout.sure_delete_task'),
      confirmText: t('tasks_layout.yes_delete'),
      onConfirm: async () => {
        try {
          await deleteTask(taskIdArray)
          navigate('/tasks')
        } catch (error) {
          console.error('Failed to delete task:', error)
        }
      },
    })
  }
  const handleEditClick = (): void => {
    setCurrentTaskId(taskId || '')
    setTaskDrawerOpened(true)
  }

  const handleActionsClick = (id?: string): void => {
    if (!id) return
    setTaskIdComment(id)
    setTaskCommentsDrawerOpened(true)
  }

  const config = columnConfig(t)
  const actions = actionsConfig(handleDeleteClick, t, handleEditClick)
  const studentAction = studentsActionConfig(handleActionsClick, t)

  const breadCrumbData = [
    {
      label: `Tasks `,
      icon: <TrendingUpOutlinedIcon style={styles.border} />,
      link: `/tasks`,
    },
    {
      label: `${name}`,
    },
  ]

  return {
    t,
    breadCrumbData,
    name,
    actions,
    description,
    createDate,
    attachment,
    tabs,
    tab,
    handleTabChange,
    taskId,
    groups,
    totalCount,
    refetch,
    loading,
    companyId,
    students,
    config,
    studentAction,
    taskDrawerOpened,
    setTaskDrawerOpened,
    taskRefetch,
    mappedCourses,
    mappedGroups,
    loadingCourses,
    groupsLoading,
    setCurrentTaskId,
    taskCommentsDrawerOpened,
    setTaskCommentsDrawerOpened,
    taskComments,
    taskCommentsLoading,
  }
}

export default useTaskDetails
