import Typography from '@mui/material/Typography'
import { Wrapper } from './styled-components'
import React from 'react'
import strCutter from 'utils/strCutter'
import { formatDuration } from 'helpers/formatDuration'
import { Action } from 'components/common/GridV2'
import Tooltip from '@mui/material/Tooltip'
import StatusIndicator from 'components/common/StatusIndicator/StatusIndicator'
import { EditSVG } from 'suada-components'
import { EditRounded } from 'suada-components'
import { ReactComponent as PlayButton } from 'assets/play-icon.svg'
import { DownloadSVG } from 'suada-components'
import { GrayPlayIcon } from 'suada-components'
import { GrayDownloadSVG } from 'suada-components'
import { TrashCanRed } from 'suada-components'
import { Locked } from 'suada-components'
import {
  IAction,
  IActionHandlerType,
  ICourse,
  ICourseListConfigReturn,
  IListColumnConfigItem,
} from './courseList.interface'
import { TranslationFunction } from 'pages/modules/ModuleDetail/ModuleTopicsGridV2/ModuleTopic.interface'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'
import { styled } from 'styled-components'

// Styled component for disabled text
const DisabledTypography = styled(Typography)`
  color: #7a7a7a;
  opacity: 0.7;
`

// Function to determine the appropriate Typography component and icon
const getTypographyAndIcon = (
  isEnabled: boolean,
  Icon: React.ElementType,
  GrayIcon: React.ElementType,
): { TypographyComponent: React.ElementType; IconComponent: React.ElementType } => {
  return {
    TypographyComponent: isEnabled ? Typography : DisabledTypography,
    IconComponent: isEnabled ? Icon : GrayIcon,
  }
}

export const courseListConfig = (t: TranslationFunction): ICourseListConfigReturn[] => {
  const grid = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: t('general.name'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <Wrapper
          // onClick={() => handelClick(item.id)}
          style={{ cursor: 'pointer' }}
        >
          {item.name.length > 30 ? (
            <Tooltip title={item.name}>
              <div>{strCutter(item.name, 30)}</div>
            </Tooltip>
          ) : (
            <div>{item.name}</div>
          )}
        </Wrapper>
      ),
    },
    {
      id: 'status',
      label: t('general.status'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <StatusIndicator
          status={GridStatusEnums[item.state.toUpperCase() as keyof typeof GridStatusEnums]}
        />
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'numOfModules',
      label: t('general.modules'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <Wrapper>{item.numberOfModules}</Wrapper>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'numberOfLessons',
      label: t('general.lessons'),
      render: (item: IListColumnConfigItem): JSX.Element => (
        <Wrapper>{item.numberOfLessons}</Wrapper>
      ),
      numeric: false,
      disablePadding: true,
    },
    {
      id: 'duration',
      label: t('general.duration'),
      render: (item: IListColumnConfigItem): JSX.Element => {
        return <Wrapper>{formatDuration(item.duration)}</Wrapper>
      },
      numeric: false,
      disablePadding: true,
    },
  ]
  return grid
}

export const courseListActions = (
  handleClick: IActionHandlerType,
  t: TranslationFunction,
  permission: IPermissionsBool,
  activeTab: number,
): IAction[] => {
  const actions = [
    {
      render: (item: ICourse): JSX.Element => {
        if (!permission.publishCourse || activeTab === 0) {
          return <React.Fragment />
        }

        return (
          <Action
            disabled={item.finished !== 3}
            onClick={(): void => {
              if (item.finished === 3) {
                handleClick(
                  'publishedUnPublished',
                  item.id,
                  item.state === 'unpublished' ? 'published' : 'unpublished',
                )
              }
            }}
          >
            <Tooltip
              title={item.state === 'unpublished' ? t('actions.publish') : t('actions.unpublish')}
              arrow
            >
              <Locked />
            </Tooltip>
          </Action>
        )
      },
    },
    {
      hide: !permission.editCourse,
      render: (item: ICourse) => (
        <Action onClick={(): void => handleClick('editCourse', item)}>
          <Tooltip title={t('actions.edit')} arrow>
            <EditRounded />
          </Tooltip>
        </Action>
      ),
    },
    {
      // Currently, the text is styled to appear disabled as the feature is not yet implemented
      render: (): JSX.Element => {
        const { TypographyComponent, IconComponent } = getTypographyAndIcon(
          false,
          DownloadSVG,
          GrayDownloadSVG,
        )
        return (
          <Action>
            <IconComponent />
            <TypographyComponent>{t('actions.download_syllabus')}</TypographyComponent>
          </Action>
        )
      },
    },
    {
      // The action is disabled if the course does not have an associated video
      render: (item: ICourse): JSX.Element => {
        const { TypographyComponent, IconComponent } = getTypographyAndIcon(
          !!item.video,
          PlayButton,
          GrayPlayIcon,
        )
        return (
          <Action onClick={(): void => handleClick('playIntro', item)} disabled={!item.video}>
            <IconComponent />
            <TypographyComponent>{t('actions.play_intro')}</TypographyComponent>
          </Action>
        )
      },
    },
    {
      hide: !permission.deleteCourse,
      render: (item: ICourse) => (
        <Action onClick={(): void => handleClick('deleteCourse', item.id)}>
          <TrashCanRed />
          <Typography>{t('actions.delete')}</Typography>
        </Action>
      ),
    },
  ]

  return actions
}

export const actionConfig = (
  handleClick: IActionHandlerType,
  permission: IPermissionsBool,
  t: TranslationFunction,
): IAction[] => [
  {
    render: (item: ICourse): JSX.Element => {
      return !item.isBought ? (
        <Action className='edit' onClick={(): void => handleClick('editCourse', item)}>
          <EditSVG />
          <Typography>{t('actions.edit')}</Typography>
        </Action>
      ) : (
        <React.Fragment />
      )
    },
  },
  {
    // Currently, the text is styled to appear disabled as the feature is not yet implemented
    render: (): JSX.Element => {
      const { TypographyComponent, IconComponent } = getTypographyAndIcon(
        false,
        DownloadSVG,
        GrayDownloadSVG,
      )
      return (
        <Action>
          <IconComponent />
          <TypographyComponent>{t('actions.download_syllabus')}</TypographyComponent>
        </Action>
      )
    },
  },
  {
    // The action is disabled if the course does not have an associated video
    render: (item: ICourse): JSX.Element => {
      const { TypographyComponent, IconComponent } = getTypographyAndIcon(
        !!item.video,
        PlayButton,
        GrayPlayIcon,
      )
      return (
        <Action onClick={(): void => handleClick('playIntro', item)} disabled={!item.video}>
          <IconComponent />
          <TypographyComponent>{t('actions.play_intro')}</TypographyComponent>
        </Action>
      )
    },
  },
  {
    hide: !permission.deleteCourse,
    render: (item: ICourse): JSX.Element => (
      <Action className='delete' onClick={(): void => handleClick('deleteCourse', item.id)}>
        <TrashCanRed />
        <DeleteSpan>{t('actions.delete')}</DeleteSpan>
      </Action>
    ),
  },
]
