import React, { ReactElement } from 'react'
import {
  Wrapper,
  Label,
  PopoverContent,
  CompanyList,
  SearchContainer,
  Container,
  CompanyOptionDiv,
} from './styled-components'
import Popover from '@mui/material/Popover'
import { SwitchCompany } from 'suada-components'
import CloseIcon from '@mui/icons-material/Close'
import { Search } from 'suada-components'
import useCompanySwitcher from 'hooks/companies/useCompanySwitcher'

const CompanySwitcher = (): ReactElement => {
  const {
    t,
    selectedCompany,
    anchorEl,
    selected,
    open,
    searchValue,
    filteredCompanies,
    handleClick,
    handleClose,
    handleRemoveCompanyState,
    handleCompanySelect,
    setSearchValue,
  } = useCompanySwitcher()

  return (
    <Container>
      <Wrapper $opened={open} selected={selected} onClick={handleClick}>
        <Label>
          {selectedCompany ? (
            <>
              <p>{selectedCompany.name}</p>
              <span onClick={handleRemoveCompanyState}>
                <CloseIcon />
              </span>
            </>
          ) : (
            <>
              <p>{t('general.switch_company')}</p>
              <span>
                <SwitchCompany />
              </span>
            </>
          )}
        </Label>
      </Wrapper>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: {
            style: {
              borderRadius: 8,
            },
          },
        }}
      >
        <PopoverContent>
          <SearchContainer>
            <Search />
            <input
              placeholder={t('form_fields.search_companies')}
              value={searchValue}
              onChange={(e): void => setSearchValue(e.target.value)}
            />
          </SearchContainer>
          <CompanyList>
            {filteredCompanies.length ? (
              filteredCompanies.map(company => (
                <CompanyOptionDiv
                  key={company.id}
                  onClick={(): void => handleCompanySelect(company)}
                  selected={selectedCompany?.id === company.id}
                >
                  {company.name}
                </CompanyOptionDiv>
              ))
            ) : (
              <span>{t('general.no_result')}</span>
            )}
          </CompanyList>
        </PopoverContent>
      </Popover>
    </Container>
  )
}

export default CompanySwitcher
