import React, { ReactElement } from 'react'
import { t } from 'i18next'
import { MultipleAction } from 'components/common/Grid/styled-components'
import IconButton from '@mui/material/IconButton'
import Grid from 'components/common/GridV2'
import {
  Header,
  LayoutWrapper,
  Wrapper,
  SelectFieldContainer,
  LoaderContainer,
} from 'pages/tests/testDetails/studentsLayout/styled-components'
import { RemoveFilter } from 'suada-components'
import { Search } from 'suada-components'
import SelectField from 'components/common/SelectField/SelectField'
import { Input } from 'components/common/TextInput'
import useStudentsLayout from './useStudentsLayout'
import { IStudentsLayout } from './students.interface'
import TablePagination from 'components/common/Pagination/TablePagination'
import { SplashScreen } from 'components/common'
import MyLoader from 'loading/loading'
import Tooltip from '@mui/material/Tooltip'

const StudentsLayout = ({ groups, companyId }: IStudentsLayout): ReactElement => {
  const {
    handleSearchChange,
    setSelectedGroup,
    selectedGroup,
    searchText,
    groupOptions,
    handleClick,
    users,
    loading,
    currentPage,
    perPage,
    handlePaginationClick,
    handleChangeRowsPerPage,
    handleResetClick,
    config,
    isSearchType,
    initialLoad,
  } = useStudentsLayout({
    groups,
    companyId,
  })

  if (!loading && users && companyId && !searchText && !isSearchType && users?.data.length === 0) {
    return (
      <>
        <SplashScreen
          title={t('user_details.no_students')}
          subTitle={t('user_details.create_students')}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      </>
    )
  }

  return (
    <LayoutWrapper>
      {!initialLoad && (
        <Header>
          <Wrapper>
            <MultipleAction>
              {selectedGroup && (
                <IconButton onClick={(): void => handleResetClick(true)}>
                  <Tooltip title={t('actions.remove_filter')} arrow>
                    <RemoveFilter />
                  </Tooltip>
                </IconButton>
              )}
            </MultipleAction>
            <SelectFieldContainer>
              <SelectField
                placeholder={'Select Group'}
                options={groupOptions}
                onChange={(e): void => setSelectedGroup(e)}
                value={selectedGroup}
              />
            </SelectFieldContainer>
            <Input
              label={t('general.search_placeholder')}
              type='text'
              size='small'
              value={searchText}
              onChange={handleSearchChange}
              icon={
                <IconButton>
                  <Search />
                </IconButton>
              }
              $iconInStart
            />
          </Wrapper>
        </Header>
      )}
      <div>
        {loading ? (
          <LoaderContainer>
            <MyLoader />
          </LoaderContainer>
        ) : (
          users && (
            <>
              {users.data.length === 0 ? (
                <SplashScreen
                  title={t('general.no_results_found')}
                  subTitle={t('general.try_adjusting_your_search_or_filter')}
                  textMarginTop='40px'
                  isSearchOrFilter={true}
                />
              ) : (
                <>
                  <Grid config={config} data={users.data} redirectToDetailsHandler={handleClick} />
                  <TablePagination
                    currentPage={currentPage}
                    rowsPerPage={perPage}
                    count={users.totalCount}
                    handleChangePage={handlePaginationClick}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </>
              )}
            </>
          )
        )}
      </div>
    </LayoutWrapper>
  )
}

export default StudentsLayout
