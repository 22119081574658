import { Button } from 'components/common/Button'
import React, { ReactElement } from 'react'
import { ReactComponent as MoreIcon } from 'components/common/Button/icons/drop.svg'
import Cropper from 'react-cropper'
import { Coach } from 'suada-components'
import Modal from '@mui/material/Modal'
import {
  UserNameText,
  UserShortInfo,
  UserRoleText,
  StyledFlex,
  ProfileBackground,
  AvatarContainer,
  InformationBackground,
  DetailsHeader,
  Half,
  ImageCropperContainer,
  ActionsWrapper,
  InputWrapper,
  ActionsGrid,
  ActionsItem,
  styles,
} from './styled-components'
import { ChangePassword } from './ChangePassword'
import EditAdminProfile from './EditProfile'
import { roles } from 'utils/permission'
import { WrapperComponent } from '../AllUserProfile/UsersProfile/Wrapper/Wrapper'
import UserDetailsComponent from '../AllUserProfile/UsersProfile/UserDetails/UserDetails'
import { StatusWrapperComponent } from '../AllUserProfile/UsersProfile/StatusWrapper/StatusWrapper'
import {
  CustomBorder,
  DetailsFlex,
  StyledBorder,
} from '../AllUserProfile/UsersProfile/styled-components'
import GridActionMenu from 'components/common/GridV2/GridActionMenu/GridActionMenu'
import { EditableAvatarV2 } from 'components/common/EditableAvatar.V2/EditableAvatar'
import { Tooltip } from '@mui/material'
import { useAdminProfile } from './hooks'

export const getRole = (role: string, isCompany?: boolean): string => {
  const roleMap = {
    [roles.super_admin]: isCompany ? 'Company Super Admin' : 'Suada Super Admin',
    [roles.admin]: isCompany ? 'Group Admin' : 'Suada Admin',
    [roles.coach]: 'Tutor',
    [roles.student]: 'Student',
    [roles.consultant]: 'Suada Consultant',
  }

  return roleMap[role] || role
}

const AdminProfile = (): ReactElement => {
  const {
    t,
    actions,
    profileData,
    avatarRef,
    handleAvatarChange,
    avatar,
    finishCrop,
    hasResponseReceived,
    role,
    firstName,
    lastName,
    isClickedOnEdit,
    setIsClickedOnEdit,
    isClickedOnChangePassword,
    setIsClickedOnChangePassword,
    cropperOpen,
    cropper,
    file,
    cropperModalToggle,
    onSave,
    visibleActions,
    infoItemsRow,
    isCompany,
  } = useAdminProfile()

  return (
    <div>
      <>
        <StyledFlex>
          <ProfileBackground>
            <ActionsWrapper>
              {actions && actions.length > 0 && (
                <GridActionMenu actionConfig={actions} row={profileData} button={<MoreIcon />} />
              )}
              <InputWrapper>
                <input
                  type='file'
                  name='file'
                  id='file'
                  ref={avatarRef}
                  onChange={handleAvatarChange}
                />
              </InputWrapper>
            </ActionsWrapper>
            <AvatarContainer>
              <EditableAvatarV2
                img={avatar}
                finishCrop={finishCrop}
                hasResponseReceived={hasResponseReceived}
                role={role ?? ''}
              />
              <UserShortInfo>
                <UserNameText>
                  {firstName} {lastName}
                  {profileData.isTutor && (
                    <Tooltip title={t('users_layout.tutor_admin_indicator')} arrow>
                      <Coach />
                    </Tooltip>
                  )}
                </UserNameText>
                <UserRoleText>{getRole(profileData.role || 'N/A', !!isCompany)}</UserRoleText>
              </UserShortInfo>
            </AvatarContainer>
            <ActionsGrid>
              {visibleActions.map((action, index) => (
                <React.Fragment key={index}>
                  <ActionsItem onClick={action.onClick}>
                    <div className='action-content'>
                      {action.icon}
                      {action.text}
                    </div>
                  </ActionsItem>
                  {index !== actions.length - 1 && <CustomBorder />}
                </React.Fragment>
              ))}
            </ActionsGrid>
          </ProfileBackground>
          <InformationBackground>
            <DetailsHeader>{t('general.detailed_information')} </DetailsHeader>
            <DetailsFlex>
              <Half>
                <StatusWrapperComponent />
                {infoItemsRow.slice(0, 4).map((item, index) => (
                  <WrapperComponent key={index} item={item} />
                ))}
              </Half>
              <StyledBorder />
              <Half>
                {infoItemsRow.slice(4).map((item, index) => (
                  <WrapperComponent key={index} item={item} />
                ))}
              </Half>
            </DetailsFlex>
          </InformationBackground>
        </StyledFlex>

        <UserDetailsComponent profileData={profileData} />
        {isClickedOnEdit && (
          <EditAdminProfile setIsClickedOnEdit={setIsClickedOnEdit} initialValues={profileData} />
        )}
        {isClickedOnChangePassword && (
          <ChangePassword setIsClickedOnChangePassword={setIsClickedOnChangePassword} />
        )}
      </>
      <Modal
        aria-labelledby='transition-modal-title'
        aria-describedby='transition-modal-description'
        open={cropperOpen}
        onClose={cropperModalToggle}
        style={styles.modal}
        closeAfterTransition
      >
        <ImageCropperContainer>
          <Cropper
            ref={cropper}
            src={file || undefined}
            style={{ height: 500, width: 600 }}
            aspectRatio={16 / 16}
            guides={false}
          />
          <div className='actions'>
            <Button
              color='primary'
              onClick={cropperModalToggle}
              background='#E0E1E2'
              textColor='#414141'
              text={t('actions.cancel')}
              type='small'
            />
            <Button
              color='primary'
              onClick={onSave}
              background='#06C68F'
              text={t('actions.save')}
              type='small'
            />
          </div>
        </ImageCropperContainer>
      </Modal>
    </div>
  )
}

export default AdminProfile
