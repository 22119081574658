import React from 'react'
import { Action } from 'components/common/GridV2'
import { EditSVG } from 'suada-components'
import { TrashCanRed } from 'suada-components'
import { TFunction } from 'interfaces/TFunction'
import { DeleteSpan } from 'components/common/Styled/styled-components'
import { IActionConfigReturn, IItemProps } from './CompanyDetails/types'
import { IPermissionsBool } from 'utils/rolesObject/rolesObject.interface'

export const actionConfig = (
  handleEditClick: (id: string) => void,
  handleDeleteClick: (id: string) => void,
  permission: IPermissionsBool,
  t: TFunction<'translation', undefined>,
): IActionConfigReturn[] => {
  const action: IActionConfigReturn[] = [
    {
      hide: !permission.editCompany,
      render: (item: IItemProps) => (
        <Action onClick={(): void => handleEditClick(item.id)}>
          <EditSVG />
          <span>{t('actions.edit')}</span>
        </Action>
      ),
    },
    {
      hide: !permission.deleteCompany,
      render: (item: IItemProps) => (
        <Action onClick={(): void => handleDeleteClick(item.id)}>
          <TrashCanRed />
          <DeleteSpan>{t('actions.delete')}</DeleteSpan>
        </Action>
      ),
    },
  ]

  return action.filter(i => !i.hide)
}
