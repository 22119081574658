import React, { useState, useEffect, useCallback, ReactElement } from 'react'
import _, { debounce } from 'lodash'
import useDeleteStudentsFromGroup from 'hooks/group/useDeleteStudentsFromGroup'
import useSendPushNotifications from 'hooks/group/useSendPushNotifications'
import { StudentContainer, ItemWrapper, StudentAction, Wrapper } from './styled-components'
import { useSwal } from 'hooks/useSwal'
import { CLEAR_FORMDATA } from 'store/types'
import { useTranslation } from 'react-i18next'
import { Toolbar, ToolbarItem } from 'components/common/Toolbar'
import { FilterIcon } from 'suada-components'
import Drawer from 'components/common/Drawer'
import AddStudentsInGroupDrawer from './AddStudentsInGroupDrawer'
import MoveToAnotherGroup from './MoveToAnotherGroup'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import IconButton from '@mui/material/IconButton'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import CircleNotificationsIcon from '@mui/icons-material/Notifications'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import { useData } from 'context/DataContext'
import { SET_CREATED_USER } from 'store/types'
import { AddStudent, FilterStudents } from 'pages/students'
import TablePagination from 'components/common/Pagination/TablePagination'
import { columnConfig, actionConfig } from './gridConfig'
import Modal from 'components/common/Modal'
import SendPushNotification from 'components/SendPushNotification/SendPushNotification'
import { PER_PAGE, useFetchStudents } from 'hooks/users/useFetchStudents'
import { TrashCan } from 'suada-components'
import GridV2 from 'components/common/GridV2'
import { Button, SplashScreen, TextInput } from 'components/common'
import { Action } from 'components/UsersLayout/styled-components'
import GridActionMenu from 'components/common/Grid/GridActionMenu/GridActionMenu'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { StudentsActionsContainer } from 'components/common/GridV2/styled-components'
import { TFunction } from 'interfaces/TFunction'
import {
  IFilterQueryType,
  ISelectedAll,
  ISelectedItem,
} from 'components/common/FilterInterface/filter.interface'
import { IGroup } from 'interfaces/groups'
import { GridStatusEnums } from 'components/common/StatusIndicator/status.interface'
import { IStudentsFormValues } from 'hooks/useStudentsForm/useStudentsForm.interface'
import MyLoader from 'loading/loading'
import { IActionConfig, IGroupStudentsFormData } from './groupStudents.interface'

const studentActionConfig = (
  handleClick: (action: string) => void,
  t: TFunction,
): IActionConfig[] => {
  const action: IActionConfig[] = [
    {
      render: () => (
        <StudentAction className='create' onClick={(): void => handleClick('create')}>
          <AddCircleRoundedIcon />
          <span>{t('actions.add_new_student')}</span>
        </StudentAction>
      ),
    },
    {
      render: () => (
        <StudentAction className='add' onClick={(): void => handleClick('addFromDb')}>
          <PlaylistAddIcon />
          <span>{t('actions.add_students_to_group')}</span>
        </StudentAction>
      ),
    },
  ]

  return action
}

interface GroupStudentsProps {
  group: IGroup
  companyId?: string
}

const GroupStudents = (props: GroupStudentsProps): ReactElement => {
  const { t } = useTranslation()
  const { fireSwal } = useSwal()
  const [dataState, dispatch] = useData()

  const [selectedItem, setSelectedItem] = useState<ISelectedItem>([])
  const [selectAll, setSelectAll] = useState<ISelectedAll>({})
  const [moveToGroupDialog, setMovetoGroupDialog] = useState(false)
  const [studentId, setStudentId] = useState<string | undefined>('')
  const [studentGroups, setStudentGroups] = useState<string[]>([])
  const [isSearchType, setIsSearchType] = useState<boolean>(false)
  const [initialLoad, setInitialLoad] = useState(true)
  const [pushNotificationModal, setPushNotificationModal] = useState(false)
  const [messageData, setMessageData] = useState<{
    heading: string
    body: string
  }>({
    heading: '',
    body: '',
  })
  const [formData, setFormData] = useState<IGroupStudentsFormData>({
    heading: '',
    body: '',
  })
  const [searchText, setSearchText] = useState('')
  const [perPage, setPerPage] = useState<number>(PER_PAGE)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const groupId = props.group?.id || ''
  const companyId = props.group?.company?.id

  const [drawer, setStudentsDrawer] = useState({
    import: false,
    addFromDb: false,
    create: false,
  })
  const filter = {
    group: {
      type: 'nestedArrayIn',
      value: [groupId],
      nestedField: 'groupId',
    },
  }
  const { users, loading, refetch } = useFetchStudents({
    ...filter,
  })
  const { deleteStudentsFromGroup } = useDeleteStudentsFromGroup(groupId)
  const { sendPushNotifications } = useSendPushNotifications()

  const selectItem = (id?: string): void => {
    const selectedItemsOnPage = selectedItem[currentPage] || []
    const selectedIndex = id ? selectedItemsOnPage.indexOf(id) : -1
    let newSelected: string[] = []

    if (selectedIndex === -1 && id) {
      newSelected = newSelected.concat(selectedItemsOnPage, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex === selectedItemsOnPage.length - 1) {
      newSelected = newSelected.concat(selectedItemsOnPage.slice(0, -1))
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedItemsOnPage.slice(0, selectedIndex),
        selectedItemsOnPage.slice(selectedIndex + 1),
      )
      setSelectAll({
        ...selectAll,
        [currentPage]: false,
      })
    }

    setSelectedItem({
      ...selectedItem,
      [currentPage]: newSelected,
    })
  }

  const selectAllItem = (): void => {
    if (!selectAll[currentPage]) {
      const newArr: string[] = users.data.map(n => n.id) as string[]
      setSelectedItem({
        ...selectedItem,
        [currentPage]: newArr,
      })
      setSelectAll({
        ...selectAll,
        [currentPage]: true,
      })
      return
    }
    setSelectedItem({
      ...selectedItem,
      [currentPage]: [],
    })
    setSelectAll({
      ...selectAll,
      [currentPage]: false,
    })
  }

  const deleteStudents = (id?: string): void => {
    const params = {
      title: 'Are you sure you want to remove student from this group?',
      onConfirm: (): void => {
        let ids: string[] = []
        id ? (ids = [id]) : (ids = [...selectedItem[currentPage]])
        if (ids.length <= 0) return
        deleteStudentsFromGroup(groupId, ids, refetch)
        setSelectedItem([])
      },
      confirmText: 'Yes, remove!',
    }

    fireSwal(params)
  }

  const handleModalClose = (): void => {
    setPushNotificationModal(false)
    setTimeout(() => {
      setMessageData({
        heading: '',
        body: '',
      })
    }, 500)
  }

  const handlePushNotificationSend = async (): Promise<false | undefined> => {
    const ids = [...selectedItem[currentPage]]
    if (ids.length <= 0) return false
    const data = await sendPushNotifications(ids, messageData)
    if (data) handleModalClose()
  }

  const toggleDialog = (): void => {
    setMovetoGroupDialog(!moveToGroupDialog)
  }

  const handleActionClick = (id?: string, action?: string, groups?: string[]): void => {
    if (action === 'delete') {
      deleteStudents(id)
      // deleteStudentsFromGroup(groupId, [id], refetch)
      return
    }

    setStudentId(id)
    if (groups) setStudentGroups(groups)

    toggleDialog()
  }

  const toggleDrawer = (field: string, value: boolean): void => {
    setStudentsDrawer({
      ...drawer,
      [field]: value,
    })
  }

  const handlePaginationClick = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ): void => {
    refetch({
      role: 'STUDENT',
      filter: { ...filter },
      currentPage: newPage + 1,
      perPage,
    })
    setCurrentPage(newPage + 1)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void => {
    refetch({
      role: 'STUDENT',
      filter: { ...filter },
      currentPage,
      perPage: parseInt(event.target.value),
    })
    setPerPage(parseInt(event.target.value, 10))
    setSelectedItem({})
  }

  useEffect(() => {
    if (!loading) {
      setInitialLoad(false)
    }
  }, [loading])

  useEffect(() => {
    if (formData) {
      const isEmpty = _.values(formData).every(_.isEmpty)
      if (isEmpty) {
        setStudentsDrawer({
          ...drawer,
          ['create']: false,
        })
        dispatch({
          type: CLEAR_FORMDATA,
          payload: true,
        })
      } else {
        fireSwal({
          title: t('popups.close_popup'),
          text: t('popups.sure'),
          onConfirm: () => {
            setStudentsDrawer({
              ...drawer,
              ['create']: false,
            })
            dispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          onClose: () => {
            setFormData({ heading: '', body: '' })
            dispatch({
              type: CLEAR_FORMDATA,
              payload: true,
            })
          },
          confirmText: 'Yes, Cancel!',
          cancelText: 'No',
        })
      }
    }
  }, [formData])

  useEffect(() => {
    if (dataState.createdObject.obj && dataState.createdObject.type === 'student') {
      refetch()
      dispatch({
        type: SET_CREATED_USER,
        payload: { obj: null, type: '' },
      })
    }
  }, [dataState.createdObject.obj])

  const config = columnConfig(selectItem, t)
  const actions = actionConfig(handleActionClick, t)

  const studentList = (users && users.data) || []

  const [filterDrawerOpened, setFilterDrawerOpened] = useState(false)
  const [filterOptions, setFilterOptions] = useState<IFilterQueryType | null>(null)

  const filterData = (filters: IFilterQueryType): void => {
    const searchedGroupIds = filters.group?.value
    const groups = searchedGroupIds ? [...searchedGroupIds, groupId] : [groupId]

    filters.group = {
      nestedField: 'groupId',
      type: 'nestedArrayAll',
      value: groups,
    }

    refetch({
      role: 'STUDENT',
      filter: {
        ...filter,
        ...filters,
      },
      currentPage,
      perPage,
    })

    setFilterOptions(filters)
  }

  const handleSearchSubmit = (searchValue: string): void => {
    const formValue = {
      search: {
        type: 'search',
        value: searchValue,
        fields: ['firstName', 'lastName', 'email', 'phone'],
      },
    }
    refetch({
      role: 'STUDENT',
      filter: { ...filter, ...filterOptions, ...formValue },
      currentPage: 1,
      perPage,
    })
  }

  const debouncedRefetch = useCallback(
    debounce((newSearchValue: string) => {
      handleSearchSubmit(newSearchValue)
      setIsSearchType(newSearchValue !== '')
    }, 500),
    [],
  )

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      const newSearchValue = e.currentTarget.value
      setSearchText(newSearchValue)
      debouncedRefetch(newSearchValue)
      setIsSearchType(true)
    },
    [debouncedRefetch],
  )

  const buttonActions = studentActionConfig((field: string) => {
    setStudentsDrawer({
      ...drawer,
      [field]: true,
    })
  }, t)

  const createButton = (): React.JSX.Element => (
    <div>
      <GridActionMenu
        actionConfig={buttonActions}
        row={{}}
        icon={
          <Button
            className='students'
            text={t('general.students')}
            size='small'
            color='secondary'
            icon={<ArrowDropDownIcon />}
            iconPosition='right'
            background='#06C68F'
          />
        }
      />
    </div>
  )

  return (
    <>
      {!loading &&
      users &&
      !isSearchType &&
      !filterOptions &&
      !users?.data?.length &&
      !searchText ? (
        <SplashScreen
          title={t('user_details.no_students')}
          marginTop={20}
          subTitle={t('user_details.create_students')}
          createButton={createButton}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      ) : (
        <StudentContainer $loading={loading}>
          <Drawer
            opened={filterDrawerOpened}
            toggleDrawer={(): void => undefined}
            totalWidth='600px'
          >
            <FilterStudents
              closeDrawer={(): void => setFilterDrawerOpened(false)}
              filter={filterData}
              filterLoading={loading}
              setFilterOptions={setFilterOptions}
              filterOptions={filterOptions}
              defaultFilter={filter}
              refetchStudents={refetch}
              manualCompanyId={companyId}
            />
          </Drawer>
          <Wrapper>
            {!initialLoad && (
              <Toolbar styles={{ justifyContent: 'space-between' }}>
                <ToolbarItem>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <GridActionMenu
                      actionConfig={buttonActions}
                      row={{}}
                      icon={
                        <Button
                          className='students'
                          text={t('general.students')}
                          size='small'
                          color='secondary'
                          icon={<ArrowDropDownIcon />}
                          iconPosition='right'
                          background='#06C68F'
                        />
                      }
                    />
                    <GridMultipleActions
                      selectedItems={
                        selectedItem[currentPage] ? selectedItem[currentPage].length : 0
                      }
                      actions={[
                        {
                          id: 0,
                          color: 'secondary',
                          tooltipText: t('actions.remove_from_group'),
                          disabled: selectedItem[currentPage]
                            ? selectedItem[currentPage].length < 1
                            : true,
                          onClick: (): void => deleteStudents(),
                          component: (
                            <Action $hoverColor='#EA382A'>
                              <TrashCan />
                            </Action>
                          ),
                        },
                        {
                          id: 1,
                          color: 'primary',
                          tooltipText: `${t('actions.send_push_notification')}`,
                          disabled: selectedItem[currentPage]
                            ? selectedItem[currentPage].length < 1
                            : true,
                          onClick: (): void => setPushNotificationModal(true),
                          component: (
                            <Action $hoverColor='#1976d2'>
                              <CircleNotificationsIcon
                                style={{
                                  color: 'rgb(179,179, 184)',
                                }}
                                fontSize='small'
                              />
                            </Action>
                          ),
                        },
                      ]}
                    />
                  </div>
                </ToolbarItem>

                <ToolbarItem>
                  <ItemWrapper>
                    <IconButton onClick={(): void => setFilterDrawerOpened(true)}>
                      <FilterIcon />
                    </IconButton>
                    <TextInput
                      label={t('general.search_placeholder')}
                      type='text'
                      size='small'
                      value={searchText}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type='submit'>
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                      $iconInStart
                      name='search'
                    />
                  </ItemWrapper>
                </ToolbarItem>
              </Toolbar>
            )}
            <div>
              {loading ? (
                <MyLoader />
              ) : (
                studentList && (
                  <>
                    {studentList.length === 0 ? (
                      <SplashScreen
                        title={t('general.no_results_found')}
                        subTitle={t('general.try_adjusting_your_search_or_filter')}
                        textMarginTop='40px'
                        isSearchOrFilter={true}
                      />
                    ) : (
                      <GridV2
                        data={studentList}
                        config={config}
                        actionConfig={actions}
                        selectAllItem={selectAllItem}
                        selected={_.size(selectedItem[currentPage])}
                        selectedItems={selectedItem[currentPage] || []}
                        openInExternalTab={true}
                        selectItem={selectItem}
                        ActionsContainer={StudentsActionsContainer}
                      />
                    )}
                    {users && users.totalCount > 0 && (
                      <TablePagination
                        currentPage={users ? users.currentPage : 0}
                        rowsPerPage={perPage}
                        count={users ? users.totalCount : 0}
                        handleChangePage={handlePaginationClick}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                      />
                    )}
                  </>
                )
              )}
            </div>
          </Wrapper>
          <Modal isOpened={pushNotificationModal} onClose={handleModalClose}>
            <div>
              <SendPushNotification
                messageData={messageData}
                setMessageData={setMessageData}
                onClose={handleModalClose}
                onSend={handlePushNotificationSend}
              />
            </div>
          </Modal>
        </StudentContainer>
      )}
      <Drawer opened={drawer.addFromDb} toggleDrawer={(): void => undefined}>
        <AddStudentsInGroupDrawer
          groupId={groupId}
          groupName={props.group.name}
          studentInGroupRefetch={refetch}
          onClose={(): void =>
            setStudentsDrawer({
              ...drawer,
              ['addFromDb']: false,
            })
          }
          companyId={companyId || ''}
        />
      </Drawer>

      <Drawer opened={moveToGroupDialog} toggleDrawer={toggleDialog}>
        <MoveToAnotherGroup
          currentGroupId={groupId}
          toggleDialog={toggleDialog}
          studentId={studentId}
          studentGroups={studentGroups}
          studentListRefetch={(): void => {
            refetch()
            toggleDialog()
          }}
          companyId={companyId || ''}
        />
      </Drawer>

      <Drawer opened={drawer.create} toggleDrawer={(): void => undefined} totalWidth='600px'>
        <AddStudent
          title={`${t('actions.create')} ${t('general.student')}`}
          role='STUDENT'
          drawerName='addStudent'
          drawerData={{
            onSuccess: (): void => refetch(),
            groupField: true,
          }}
          onClose={(): void => toggleDrawer('create', false)}
          setFormData={(data: IStudentsFormValues): void =>
            setFormData(data as unknown as IGroupStudentsFormData)
          }
          group={{
            groupId: groupId,
            name: props.group?.name,
            status: props.group?.status as GridStatusEnums,
          }}
        />
      </Drawer>
    </>
  )
}

export default GroupStudents
