import React from 'react'
import { Input } from 'components/common/TextInput'
import SearchRoundedIcon from '@mui/icons-material/SearchRounded'
import IconButton from '@mui/material/IconButton'
import Drawer from 'components/common/Drawer'
import { FilterIcon } from 'suada-components'
import { NewFilter } from 'suada-components'
import { RemoveFilter } from 'suada-components'
import { HorizontalView } from 'suada-components'
import { GridView } from 'suada-components'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import { TrashCan } from 'suada-components'

import {
  Header,
  Wrapper,
  Title,
  Text,
  toolTipStyles,
  HeaderContainer,
  TitleContainer,
  GridTableWrapper,
  StyledPagination,
} from './styled-components'
import QuizList from './QuizList/QuizList'
import FilterDrawer from './FilterDrawer/FilterDrawer'
import { Tooltip } from '@mui/material'
import { IQuizLayoutProps } from './quiz.interface'
import { roles } from 'utils/permission'

import PaginationV2 from 'components/common/PaginationV2'
import { SplashScreen } from 'components/common'
import useQuizLayout from 'hooks/quizzes/useQuizLayout'
import MyLoader from 'loading/loading'
import { LoaderContainer } from 'pages/tests/testDetails/studentsLayout/styled-components'
import { ToolbarItem } from 'components/common/Toolbar'
import { Action } from 'components/UsersLayout/styled-components'
import GridV2 from 'components/common/GridV2/Grid'
import PerPageDropdown from 'components/common/PerPageDropDown/PerPageDropDown'

const QuizLayout = ({
  type,
  id,
  routerState,
  companyCourse,
  isDraggable,
  gridStyle,
  IQTest,
  canCreate,
  fromQuestions,
  setOpenCreateQuizDrawer,
  hasCheckbox,
}: IQuizLayoutProps): React.JSX.Element => {
  const {
    state,
    handleSearchChange,
    t,
    setCurrentPage,
    searchText,
    selectItem,
    selectedItem,
    setFilterData,
    setFilterDrawer,
    setSearchText,
    companyId,
    filterData,
    filterDrawer,
    handlePaginationClick,
    quizList,
    currentPage,
    actions,
    onSearchSubmit,
    fromList,
    deleteQuizzes,
    currentUser,
    CreateAssessmentButton,
    createIqTestBtnDisabled,
    isSearchType,
    createButton,
    quizListLoading,
    isFiltered,
    initialLoad,
    isGridView,
    setIsGridView,
    quizListConfiguration,
    selectedItems,
    selectAllItem,
    redirectToTest,
    gridActions,
    perPage,
    handleChangeRowsPerPage,
  } = useQuizLayout({
    canCreate,
    companyCourse,
    fromQuestions,
    id,
    IQTest,
    setOpenCreateQuizDrawer,
    type,
  })

  // for SUADA consultants it is necessary to select a company to use the quizzes page
  if (state.currentUser?.role === roles.consultant && !state.selectedCompany?.id) {
    return <SplashScreen subTitle={t('quizzes_layout.switch_company')} />
  }

  if (!isFiltered && !searchText && !quizList.data?.length && !isSearchType && !quizListLoading) {
    const isIQTest = IQTest
    const showCreateAssessmentButton =
      fromList && state?.userPermission?.createModule && !createIqTestBtnDisabled

    return (
      <>
        <SplashScreen
          title={t(isIQTest ? 'quizzes_layout.no_assessments' : 'quizzes_layout.no_quizzes')}
          subTitle={t(
            isIQTest
              ? 'quizzes_layout.create_assessments_information'
              : 'quizzes_layout.create_quizzes_information',
          )}
          createButton={showCreateAssessmentButton && createButton}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      </>
    )
  }

  return (
    <div style={{ paddingTop: '20px', minHeight: '300px' }}>
      {!initialLoad && (
        <Header className={'withBorder'}>
          <Wrapper>
            <HeaderContainer>
              <TitleContainer>
                {!companyCourse && (
                  <>
                    {fromList && state?.userPermission?.createModule ? (
                      createIqTestBtnDisabled ? (
                        <Tooltip
                          enterDelay={500}
                          title={
                            <span style={toolTipStyles}>
                              {t('general.disabled_assessment_button_tooltip')}
                            </span>
                          }
                          arrow
                        >
                          <div>
                            <CreateAssessmentButton />
                          </div>
                        </Tooltip>
                      ) : (
                        <CreateAssessmentButton />
                      )
                    ) : (
                      <Title>{t('quizzes_layout.title')}</Title>
                    )}

                    {!IQTest && state.userPermission.deleteQuiz && (
                      <GridMultipleActions
                        selectedItems={
                          selectedItem[currentPage] ? selectedItem[currentPage].length : 0
                        }
                        actions={
                          companyCourse && currentUser.companyId
                            ? []
                            : [
                                {
                                  id: 0,
                                  color: 'secondary',
                                  disabled:
                                    !selectedItem[currentPage] ||
                                    selectedItem[currentPage].length < 1,
                                  onClick: () => deleteQuizzes(selectedItem[currentPage]),
                                  component: (
                                    <Action $hoverColor='#EA382A'>
                                      <Tooltip title={t('actions.delete')} arrow>
                                        <TrashCan />
                                      </Tooltip>
                                    </Action>
                                  ),
                                },
                              ]
                        }
                      />
                    )}
                  </>
                )}
              </TitleContainer>
              <Text>
                {IQTest
                  ? t('quizzes_layout.iq_test_rule')
                  : t('quizzes_layout.quiz_recommendation')}
              </Text>
            </HeaderContainer>

            <ToolbarItem>
              {isGridView ? (
                <IconButton onClick={(): void => setIsGridView(false)}>
                  <GridView />
                </IconButton>
              ) : (
                <IconButton onClick={(): void => setIsGridView(true)}>
                  <HorizontalView />
                </IconButton>
              )}
            </ToolbarItem>

            <div style={{ display: 'flex', alignContent: 'center' }}>
              {!fromList && (
                <>
                  <IconButton onClick={(): void => setFilterDrawer(true)}>
                    <Tooltip title={t('actions.filter')} arrow>
                      {isFiltered ? <NewFilter /> : <FilterIcon />}
                    </Tooltip>
                  </IconButton>
                  {isFiltered && (
                    <IconButton
                      onClick={(): void => {
                        setFilterData({
                          filterOptions: null,
                          values: null,
                        })
                        setSearchText('')
                      }}
                    >
                      <Tooltip title={t('actions.remove_filter')} arrow>
                        <RemoveFilter />
                      </Tooltip>
                    </IconButton>
                  )}
                </>
              )}

              {!fromList && (
                <div style={{ marginRight: '20px' }}>
                  <form onSubmit={onSearchSubmit}>
                    <Input
                      label={t('general.search_placeholder')}
                      type='text'
                      size='small'
                      value={searchText}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton type='submit'>
                          <SearchRoundedIcon />
                        </IconButton>
                      }
                    />
                  </form>
                </div>
              )}
            </div>
          </Wrapper>
        </Header>
      )}

      {quizListLoading ? (
        <LoaderContainer>
          <MyLoader />
        </LoaderContainer>
      ) : (
        quizList && (
          <>
            {quizList.data.length === 0 && (searchText !== '' || isFiltered) ? (
              <SplashScreen
                title={t('general.no_results_found')}
                subTitle={t('general.try_adjusting_your_search_or_filter')}
                textMarginTop='40px'
                isSearchOrFilter={true}
              />
            ) : (
              <>
                {quizList.data.length > 0 ? (
                  <>
                    {!isGridView ? (
                      <GridTableWrapper style={{ width: '100%' }}>
                        <GridV2
                          config={quizListConfiguration}
                          data={quizList.data}
                          actionConfig={gridActions}
                          actionTilesLength={1}
                          selectItem={selectItem}
                          selectedItems={selectedItems}
                          selected={selectedItems.length}
                          selectAllItem={(): void =>
                            selectAllItem(
                              quizList.data
                                .map(item => item.id)
                                .filter((id): id is string => id !== undefined),
                            )
                          }
                          redirectToDetailsHandler={(item): void => redirectToTest(item.id)}
                        />
                      </GridTableWrapper>
                    ) : (
                      <QuizList
                        IQTest={IQTest}
                        data={quizList.data || []}
                        actions={actions}
                        gridStyle={gridStyle || 'card'}
                        selectedItems={selectedItem[currentPage] || []}
                        selectItem={selectItem}
                        isDraggable={isDraggable ? true : false}
                        isOriginalCourse={companyCourse}
                        type={type}
                        routerState={routerState}
                        t={t}
                        hasCheckbox={hasCheckbox ?? true}
                      />
                    )}
                    <StyledPagination>
                      {!isGridView && (
                        <PerPageDropdown
                          value={perPage}
                          onChange={(value): void =>
                            handleChangeRowsPerPage({
                              target: {
                                value: value.toString(),
                              } as HTMLInputElement,
                            } as React.ChangeEvent<HTMLInputElement>)
                          }
                          options={[10, 20, 50]}
                        />
                      )}
                      <PaginationV2
                        currentPage={quizList ? quizList.currentPage : 1}
                        totalPages={quizList ? quizList.totalPages : 0}
                        handleChange={handlePaginationClick}
                      />
                    </StyledPagination>
                  </>
                ) : (
                  <MyLoader />
                )}
              </>
            )}
          </>
        )
      )}

      <Drawer opened={filterDrawer} toggleDrawer={(): void => undefined} totalWidth='700px'>
        <FilterDrawer
          onClose={(): void => setFilterDrawer(false)}
          setFilterData={setFilterData}
          filterData={filterData}
          companyId={companyId as string}
          setCurrentPage={setCurrentPage}
          t={t}
        />
      </Drawer>
    </div>
  )
}

export default QuizLayout
