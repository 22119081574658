import React, { ReactElement } from 'react'
import { Button } from 'components/common/Button'
import AddCircleIcon from '@mui/icons-material/Add'
import { t } from 'i18next'
import IconButton from '@mui/material/IconButton'
import { Header, LayoutWrapper, Wrapper } from './styled-components'
import { RemoveFilter } from 'suada-components'
import { Search } from 'suada-components'
import Drawer from 'components/common/Drawer'
import Grid from 'components/common/GridV2'
import _ from 'lodash'
import GridMultipleActions from 'components/common/Grid/GritMultipleActions'
import { ToolbarItem } from 'components/common/Toolbar'
import { Input } from 'components/common/TextInput'
import FilterDrawer from 'pages/group/FilterDrawer/FilterDrawer'
import MyLoader from 'loading/loading'
import { TrashCan } from 'suada-components'
import TablePagination from 'components/common/Pagination/TablePagination'
import { IGroupFilterDrawerOptions } from 'pages/group/FilterDrawer/filterDrawer.interface'
import useTaskGroupLayout from 'hooks/tasks/useTaskGroupLayout'
import { ITaskGroupsLayout } from './groupsLayout.interface'
import { AddGroupToTask } from '../addGroupToTask'
import { SplashScreen } from 'components/common'
import Tooltip from '@mui/material/Tooltip'
import { LoaderContainer } from 'pages/tests/testDetails/studentsLayout/styled-components'

const GroupsLayout = ({
  taskId,
  groups,
  totalGroupCount = 0,
  groupsLoading,
  refetchGroups,
  companyId,
}: ITaskGroupsLayout): ReactElement => {
  const {
    state,
    onSearchSubmit,
    handleAddGroupsToTask,
    handleRemoveGroupsFromTask,
    config,
    actions,
    handleResetClick,
    selectItem,
    selectAllItem,
    handleClick,
    setAddGroupsToTask,
    selectedItemsOnPage,
    filterDrawer,
    selectedItem,
    handleChangeGroupRowsPerPage,
    handleGroupsPaginationClick,
    searchText,
    createButton,
    setFilterDrawer,
    perPage,
    currentPage,
    setCurrentPage,
    addGroupsToTask,
    handleSearchChange,
    isFiltered,
    filterIcon,
    isResetDisabled,
    isSearchType,
  } = useTaskGroupLayout({
    taskId,
    refetchGroups,
    groups,
  })

  return (
    <>
      {!groupsLoading &&
      !groups.length &&
      !isSearchType &&
      !(searchText || Object.values(filterDrawer.filterOptions ?? {}).length) ? (
        <SplashScreen
          title={t('tasks_layout.no_groups')}
          marginTop={20}
          subTitle={t('tasks_layout.add_groups')}
          createButton={createButton}
          textMarginTop='40px'
          isSearchOrFilter={true}
        />
      ) : (
        <LayoutWrapper>
          <Header>
            <Wrapper>
              <Wrapper>
                <Button
                  text={t('tasks_layout.add_group')}
                  color='secondary'
                  onClick={(): void => setAddGroupsToTask(true)}
                  icon={<AddCircleIcon />}
                  background='#06C68F'
                />
                <GridMultipleActions
                  selectedItems={selectedItemsOnPage.length}
                  actions={[
                    {
                      id: 0,
                      color: 'secondary',
                      hide: !state.userPermission.deleteModule,
                      tooltipText: `${t('actions.delete')}`,
                      disabled: selectedItemsOnPage.length < 1,
                      onClick: (): void => handleRemoveGroupsFromTask(selectedItemsOnPage),
                      component: <TrashCan />,
                    },
                  ].filter((i: { hide: boolean }) => !i.hide)}
                />
              </Wrapper>
              <Wrapper>
                <ToolbarItem $noGap>
                  <IconButton
                    onClick={(): void =>
                      setFilterDrawer((prev: IGroupFilterDrawerOptions) => ({
                        ...prev,
                        opened: true,
                      }))
                    }
                  >
                    <Tooltip title={t('actions.filter')} arrow>
                      {filterIcon}
                    </Tooltip>
                  </IconButton>
                  {isFiltered && (
                    <IconButton disabled={isResetDisabled} onClick={handleResetClick}>
                      <Tooltip title={t('actions.remove_filter')} arrow>
                        <RemoveFilter />
                      </Tooltip>
                    </IconButton>
                  )}
                </ToolbarItem>
                <ToolbarItem>
                  <form onSubmit={onSearchSubmit}>
                    <Input
                      label={t('general.search_placeholder')}
                      type='text'
                      size='small'
                      value={searchText}
                      onChange={handleSearchChange}
                      icon={
                        <IconButton onClick={onSearchSubmit}>
                          <Search />
                        </IconButton>
                      }
                      $iconInStart
                    />
                  </form>
                </ToolbarItem>
              </Wrapper>
            </Wrapper>
          </Header>
          {groupsLoading && !groups.length ? (
            <LoaderContainer>
              <MyLoader />
            </LoaderContainer>
          ) : groups.length ? (
            <div>
              <Grid
                data={groups}
                indexOfSpecificWidth={0}
                config={config}
                redirectToDetailsHandler={handleClick}
                actionConfig={actions}
                selectItem={selectItem}
                loading={groupsLoading}
                selectAllItem={selectAllItem}
                selected={_.size(selectedItem[currentPage] || [])}
                selectedItems={selectedItem[currentPage] || []}
                resetVisible={false}
              />
              <TablePagination
                currentPage={currentPage}
                rowsPerPage={perPage}
                count={totalGroupCount}
                handleChangePage={handleGroupsPaginationClick}
                handleChangeRowsPerPage={handleChangeGroupRowsPerPage}
              />
            </div>
          ) : searchText || Object.values(filterDrawer.filterOptions ?? {}).length ? (
            <SplashScreen
              title={t('general.no_results_found')}
              subTitle={t('general.try_adjusting_your_search_or_filter')}
              textMarginTop='40px'
              isSearchOrFilter={true}
            />
          ) : null}
        </LayoutWrapper>
      )}
      <Drawer opened={addGroupsToTask} toggleDrawer={(): void => undefined} totalWidth='600px'>
        <AddGroupToTask
          onClose={(groupIds?: string[]): void => {
            groupIds ? handleAddGroupsToTask(groupIds) : setAddGroupsToTask(false)
          }}
          taskId={taskId}
          companyId={state.selectedCompany?.id as string}
          hasSearchByCompany={false}
        />
      </Drawer>
      <Drawer opened={filterDrawer.opened} toggleDrawer={(): void => undefined} totalWidth='700px'>
        <FilterDrawer
          closeDrawer={(): void => setFilterDrawer({ ...filterDrawer, opened: false })}
          hideCourseSelectField
          setFilterOptions={setFilterDrawer}
          refetchGroup={refetchGroups}
          setCurrentPage={setCurrentPage}
          defaultFilter={{}}
          companyId={companyId}
          filterOptions={filterDrawer}
        />
      </Drawer>
    </>
  )
}

export default GroupsLayout
